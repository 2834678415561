<template lang="pug">
  .messages
    Message(
      v-for="(message, index) of messages"
      :message="message"
      :messages="messages"
      :key="index"
      :speechName='speechName'
      :streamName='streamName'
      @show-reply-to-message-modal="showReplyToMessageModal"
      @open-picture="openPicture"
    )
    ZeenModal(
      v-model='show'
      name="message-picture"
    )
      template(v-slot='{params}')
        img(:src="picture" alt="img")
</template>

<script>
import Message from './Message';
import ZeenModal from '../ui/ZeenModal'

export default {
  name: "Messages",
  components: {Message, ZeenModal},
  props: {
    messages: Array,
    speechName: String,
    streamName: String
  },
  data() {
    return {
      picture: '',
      show: false,
    }
  },
  mounted() {
    this.showMessageScrollBottom()
    // this.createIntervalLoadChats()
  },
  destroyed() {
    this.hiddenMessageScrollBottom()
    clearInterval(this.intervalLoadChats)
  },
  methods: {
    // createIntervalLoadChats() {
    //   this.intervalLoadChats = setInterval(() => {
    //     this.$store.dispatch('chats/loadChats', this.$route.params.roomId)
    //   }, 5000)
    // },
    showReplyToMessageModal(data) {
      this.$emit('show-reply-to-message-modal', data)
    },
    showNotify() {
      let scrollTop = document.documentElement.scrollTop
      let innerHeight = window.innerHeight
      let offsetHeight = document.documentElement.offsetHeight
      let bottomOfWindow = scrollTop + innerHeight === offsetHeight

      if (bottomOfWindow) {
        this.$notify({
          type: 'success',
          text: this.$t(`notifications.all_messages_reviewed`),
          max: 1,
          ignoreDuplicates: true
        })
      }
    },
    showMessageScrollBottom() {
      window.addEventListener('scroll', this.showNotify, {
        capture: false,
        once: false
      })
    },
    hiddenMessageScrollBottom() {
      window.removeEventListener('scroll', this.showNotify)
    },
    openPicture(src) {
      this.picture = src
      this.$vfm.show('message-picture')
    },
  },
}
</script>

<style scoped lang="scss">
</style>