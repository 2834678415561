<template lang="pug">
  .form
      ZeenTitle.title(
        size="1"
        uiStyle="")
        span {{$t(`modals.reply_to_message.title`)}}
      .author
        .author__avatar
          .icon-profile
        span {{userName}}
      .message
        .message__description {{$t(`modals.reply_to_message.description`)}}
        span {{text}}
      .answer
        .answer__description {{$t(`modals.reply_to_message.answer_description`)}}
        ZeenTextInput.answer__input(
          type="textarea"
          v-model='message'
          :placeholder="$t(`modals.reply_to_message.placeholder`)")
      InputFile(
        :text="$t(`modals.reply_to_message.input_file_text`)"
        :label="$t(`modals.reply_to_message.input_file_label`)"
        :textForBtn="$t(`modals.reply_to_message.input_file_btn`)"
        :image="file"
        @change-input="fileExists"
        @remove='removePicture'
      )

      .buttons
        ZeenButton.buttons__button.buttons__button-outline(
          theme="outline"
          @click.prevent="closeModal") {{$t(`modals.reply_to_message.cancel_btn`)}}
        ZeenButton.buttons__button(@click.prevent='sendMessage') {{$t(`modals.reply_to_message.send_btn`)}}
</template>

<script>
import InputFile from '../../ui/InputFile';
export default {
  name: 'ReplyToMessageForm',
  components: {InputFile},
  data() {
    return {
      file: null,
      message: ''
    }
  },
  props: {
    isOpen: Boolean,
    data: Object
  },
  methods: {
    fileExists(file) {
      this.file = file
    },
    removePicture() {
      this.file = null
    },
    closeModal() {
      this.$vfm.hide('reply-to-message-modal')

      this.message = ''
      this.file = null
    },
    sendMessage() {
      let formData = new FormData()
      formData.append('id', this.data.id)
      formData.append('chat_item[reply]', this.message)
      formData.append('chat_item[picture]', this.file)

      this.$store.dispatch('chats/replyMessage', {data: formData, id: this.data.id})
      .then(()=>{
        this.message = ''
        this.file = null

        this.$notify({
          type: 'success',
          text: this.$t(`notifications.successfully_sent`)
        })
        this.$store.dispatch('chats/loadChats', this.$route.params.roomId)
        this.closeModal()
      })
      .catch((data)=>{
        this.$notify({
          type: 'error',
          text: this.$t(`notifications.error`) + data
        })
      })
    }
    
  },
  computed: {
    userName() {
      return this.data?.userName ?? ""
    },
    text() {
      return this.data?.text ?? ""
    },
    
  },
  watch: {
    isOpen() {
      this.message = ''
      this.file = null
    }
  }
}
</script>

<style lang="scss" scoped>
  .title {
    font-size: 24px;
    font-weight: 600;
  }

  .author {
    padding-top: 30px;
    display: flex;
    align-items: center;
    font-size: 12px;
    font-weight: 600;

    &__avatar {
      width: 20px;
      height: 20px;
      border-radius: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #fff;
      font-size: 13px;
      background-color: #999;
      margin-right: 10px;
    }
  }

  .message {
    padding-top: 27px;
    font-size: 18px;

    &__description {
      padding-bottom: 6px;
      font-size: 12px;
      color: #999;
    }
  }

  .answer {
    padding-top: 24px;

    &__description {
      padding-bottom: 6px;
      font-size: 12px;
      color: #999;
    }
  }
  .file-input-wrapper {
    padding-top: 17px;
  }

  .attached-image {
    padding-top: 24px;
    font-size: 12px;
    color: #999;

    .content-wrapper {
      display: flex;
      align-items: center;
      position: relative;

        &__img {
          position: relative;
          margin-top: 10px;
          width: 180px;
          height: 130px;
          border-radius: 10px;
        }

        &__btn {
          cursor: pointer;
          position: absolute;
          top: 20px;
          left: 145px;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: #fff;
          font-size: 8px;
          font-weight: 900;
          color: #999;
          padding: 6px;
          border-radius: 20px;
        }

      .replace-photo {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        &__input {
          position: absolute;
          visibility: hidden;
        }

        &__label {
          color: #a672ff;
          font-size: 16px;
          font-weight: 600;
          cursor: pointer;
        }
      }
    }
  }

  .buttons {
    display: flex;
    padding-top: 30px;

    &__button {
      flex-grow: 1;
    }

    &__button-outline {
      margin-right: 15px;
    }
  }
</style>