<template lang="pug">
  .question(:class="questionClass")
    .question-header
      .main-info(:class="mainInfoClass")
        .main-info__avatar(v-if="!loading")
          .icon-profile
          .icon-block(v-if="iconBlockVisible")
        .main-info__avatar_loading(v-if="loading")
          LoadBox(:height="20" :rx="20" :ry="20")
        .main-info__user-name(v-if="!loading") {{question.userName}}
        .main-info__user-name_loading(v-else)
          LoadBox(:height="22")
        .main-info__address-to(v-if="speakerName && !loading")
          .icon-direction
          .address-to__avatar(:style='{backgroundImage: "url(" + question.speaker.picture + ")"}')
          .address-to__user-name {{speakerName}}
        .main-info__time(v-if="!loading") {{time}}
        .main-info__time_loading(v-else)
          LoadBox(:height="22")
        .main-info__user-id(v-if="!loading") ID  {{question.id}}
        .main-info__user-id_loading(v-else)
          LoadBox(:height="22")
      .question-header__status(v-if="!loading")
        p.question-header__answer {{ $t(`question.answer.${questionIsAnswered}`)}}
        p {{question.status}}
        .icon.icon-check-mark(v-if="question.status === 'approved'")
        .icon.icon-block(v-if="question.status === 'rejected'")
        .icon.icon-simple-clock(v-if="question.status === 'pending'")
      .question-header__status_loading(v-if="loading")
        LoadBox(:height="22")
    .question-body
      .secondary-info(v-if="!loading")
        .secondary-info__title(
          :class="speechName ? 'secondary-info__title_framed' : ''")
          span.secondary-info__stream-name {{streamName}}
          Tooltip.stream-tooltip(
            :tooltip="streamName"
            position="top-right"
            width="max-content")
        .secondary-info__title
          span.secondary-info__speech-name {{speechName}}
          Tooltip.speech-tooltip(
            :tooltip="speechName"
            position="top-right"
            width="max-content")
      .to-answer(v-if="question.is_reply && !loading")
        .to-answer__author {{question.userReply}}
        .to-answer__content {{question.text}}
      .question-body__content(v-if="!loading") {{questionText}}
      .question-body__content_loading(v-else)
        LoadBox(:height="22")
      .question-body__buttons(v-if="!question.isAdmin && !loading")
        .buttons-group
          ZeenButton.button.button-reply(
            theme="string"
            :disabled="question.status === 'rejected' || question.userBan"
            @click.prevent="showReplyToQuestionModal")
            .icon.icon-reply
            span {{$t(`question.buttons.answer`)}}
          ZeenButton.button(
            @click.prevent='rejectQuestion'
            theme="string"
            v-if="question.status === 'approved'")
            .icon.icon-block
            span {{$t(`question.buttons.reject`)}}
          ZeenButton.button.button-to-publish(
            @click.prevent='approveQuestion'
            theme="string"
            :disabled="question.userBan"
            v-if="question.status === 'rejected' || question.status === 'pending'")
            .icon.icon-check-mark
            span {{$t(`question.buttons.post`)}}
          Tooltip.tooltip-to-publish(
            v-if="question.userBan"
            position="top-right"
            :tooltip="$t(`ui.tooltips.unban_autor`)"
            width="max-content")
          ZeenButton.button(
            v-if="!question.userBan"
            @click.prevent='userBan'
            theme="string"
            )
            .icon.icon-lock
            span {{$t(`question.buttons.ban`)}}
          ZeenButton.button(
            v-if="question.userBan"
            @click.prevent='userUnban'
            theme="string"
            )
            .icon.icon-unlock
            span {{$t(`question.buttons.unban`)}}
          ZeenButton.button(
            @click='answered'
            theme="string")
            .icon(:class='iconClass')
            span {{ $t(`question.buttons.mark.${questionIsAnswered}`)}}
        ZeenButton.button-likes(
          theme="string"
          :class="{'button-likes_no-likes': question.likes === 0}")
          .icon.icon-heart(v-if="question.likes === 0")
          .icon.icon-heart-stroke(v-else)
          span {{question.likes}} 
      .question-body__buttons(v-if="question.is_reply && question.status === 'approved' && !loading")
        .buttons-group
          ZeenButton.button(
            theme="string"
            @click.prevent="rejectQuestion")
            .icon.icon-basket
            span {{$t(`question.buttons.delete`)}}
        ZeenButton.button-likes(
          theme="string"
          :class="{'button-likes_no-likes': question.likes === 0}")
          .icon.icon-heart(v-if="question.likes === 0")
          .icon.icon-heart-stroke(v-else)
          span {{question.likes}}
      .question-body__buttons_loading(v-if="loading")
        .button-first
          LoadBox(:height="35")
        .button-second
          LoadBox(:height="35")
        .button-third
          LoadBox(:height="35")
</template>

<script>
import LoadBox from '../ui/LoadBox';
import Tooltip from '../ui/Tooltip';
import moment from 'moment'

export default {
  name: "Question",
  components: {LoadBox, Tooltip},
  props: {
    question: Object,
    questions: Array,
    speechName: String,
    streamName: String
  },
  data() {
    return {
      loading: false
    }
  },
  methods: {
    showReplyToQuestionModal() {
      this.$emit('show-replay-to-question-modal', {data: this.question})
    },
    rejectQuestion() {
      this.$api.project['admin.question.reject'](this.question.id)
        .then(()=> {
          this.question.status = 'rejected'
          this.$notify({
            type: 'success',
            text: this.$t(`notifications.question_rejected`)
          })
        })
        .catch((data)=>{
        this.$notify({
          type: 'error',
          text: this.$t(`notifications.error`) + data
        })
      })
    },
    approveQuestion() {
      this.$api.project['admin.question.approve'](this.question.id)
        .then(()=> {
          this.question.status = 'approve'
          this.$notify({
            type: 'success',
            text: this.$t(`notifications.question_approved`)
          })
        })
        .catch((data)=>{
        this.$notify({
          type: 'error',
          text: this.$t(`notifications.error`) + data
        })
      })
    },
    userBan() {
      this.$api.project['admin.question.user.ban'](this.question.id)
        .then(()=> {
          this.questions.forEach(item => {
            if (item.userId === this.question.userId) {
              this.$api.project['admin.question.reject'](item.id)
                  .then(() => {
                    item.status = 'rejected'
                  })
            }
            this.$store.dispatch('questions/loadQuestions', this.$route.params.roomId)
          })
          this.$notify({
            type: 'success',
            text: this.$t(`notifications.user_was_banned`)
          })
        })
        .catch((data)=>{
        this.$notify({
          type: 'error',
          text: this.$t(`notifications.error`) + data
        })
      })
    },
    userUnban() {
      this.$api.project['admin.question.user.unban'](this.question.id)
          .then(()=> {
            this.$store.dispatch('questions/loadQuestions', this.$route.params.roomId)
            this.$notify({
              type: 'success',
              text: this.$t(`notifications.user_was_unbanned`)
            })
          })
          .catch((data)=>{
            this.$notify({
              type: 'error',
              text: this.$t(`notifications.error`) + data
            })
          })
    },
    answered() {
      this.$api.project['admin.question.answered'](this.question.id)
        .then(()=> {
          this.$store.dispatch('questions/loadQuestions', this.$route.params.roomId)
          this.$notify({
            type: 'success',
            text: this.$t(`notifications.answered`)
          })
        })
        .catch((data)=>{
        this.$notify({
          type: 'error',
          text: this.$t(`notifications.error`) + data
        })
      })
    }
  },
  computed: {
    questionClass() {
      if (this.question.status === 'rejected' && !this.question.userBan) {
        return 'question_blocked'
      }
      if (this.question.userBan) {
        return 'question_user-blocked'
      }
      if (this.question.status === 'pending') {
        return 'question_moderation'
      }
      return ''
    },
    mainInfoClass() {
      if(this.question.isAdmin) {
        return 'main-info_admin'
      }
      return ''
    },
    iconBlockVisible() {
      return !!(this.question.userBan && !this.question.isAdmin)
    },
    time() {
      return moment(this.question.created_at).format('HH:mm')
    },
    questionText() {
      return this.question.is_reply ? this.question.reply_text : this.question.text
    },
    speakerName() {
      return this.question.speaker?.name ?? "" 
    },
    chatRoomId() {
      return this.$store.getters['chatrooms/getChatroomById'](this.$route.params.roomId).id
    },
    questionIsAnswered() {
      return this.question?.is_answered
    },
    iconClass() {
      return this.questionIsAnswered ? 'icon-answered' : 'icon-not-answered'
    },
  }
}
</script>

<style scoped lang="scss">
.question {
  border-bottom: 1px solid #e5e5e5;
  padding: 20px 0 30px;

  &_moderation {
    .question-header {
      &__status {
        color: #dc831a;
      }
    }
  }

  &_blocked {
    .question-header {
      &__status {
        color: #ff4d4d;
      }
    }
    .main-info {
      &__user-name {
        color: #999;
      }
      &__address-to {
        .icon-direction {
          color: #999 !important;
      }
        .address-to {
          &__avatar {
            background-size: contain;
          }
          &__user-name {
            color: #999 !important;
          }
        }
      }
    }
    .question-body {
      color: #999;

      .buttons-group {
        .button-reply{
          color: #999;

          .icon-reply {
            color: #999;
          }
        }
      }
    }
  }

  &_user-blocked {
    .question-header {
      &__status {
        color: #ff4d4d;
      }
    }
    .main-info {
      &__avatar {
        position: relative;
        background-color: #222 !important;

        &_loading {
          width: 20px;
          margin-right: 10px;
        }
        .icon-block {
          position: absolute;
          color: #ff4d4d;
          font-size: 14px;
        }
      }
      &__user-name {
        color: #ff4d4d;
      }

      &__address-to {
        .icon-direction {
          color: #999 !important;
        }
        .address-to {
          &__avatar {
            background-color: #999 !important;
          }
          &__user-name {
            color: #999 !important;
          }
        }
      }
    }
    .question-body {
      color: #999;
      .buttons-group {
        position: relative;
        .button-reply {
          color: #999;
          .icon-reply {
            color: #999;
          }
        }
        .button-to-publish {
          color: #999;

          &:hover + .tooltip-to-publish {
            ::v-deep .tooltip {
              display: flex;
              font-size: 12px;
              position: absolute;
              left: -100px;
            }
          }
          .icon-check-mark {
            color: #999;
          }
        }
      }
    }
  }
}

.question-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.4;

  &__answer {
    color: #999;
    margin: 0 20px 0 0;
  }

  &__status {
    display: flex;
    align-items: center;
    color: #00943e;

    &_loading {
      width: 122px;
    }

    .icon {
      margin-left: 8px;
      font-size: 14px;
    }
  }

  .main-info {
    display: flex;
    align-items: center;

    &_admin {
      .main-info__avatar {
        background-color: #a672ff !important;
      }
      .main-info__user-name {
        color: #a672ff !important;
      }
    }

    &__avatar {
      background-color: #999;
      width: 20px;
      height: 20px;
      border-radius: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
      font-size: 13px;

      &_loading {
        width: 20px;
        margin-right: 10px;
      }
    }

    &__user-name {
      font-size: 12px;
      font-weight: 600;
      padding-left: 10px;

      &_loading {
        width: 107px;
        margin-right: 14px;
      }
    }

    &__address-to {
      display: flex;
      align-items: center;
      padding-left: 15px;

      .icon-direction {
        font-size: 5px;
        color: #a672ff;
        padding-right: 10px;
      }

      .address-to {
        &__avatar {
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: #a672ff;
          background-size: contain;
          width: 20px;
          height: 20px;
          border-radius: 20px;
          font-size: 13px;
          margin-right: 10px;

          .icon-profile {
            color: #fff;
          }
        }

        &__user-name {
          color: #a672ff;
          font-size: 12px;
          font-weight: 600;
        }
      }
    }

    &__time {
      font-size: 12px;
      color: #999;
      padding-left: 15px;

      &_loading {
        width: 33px;
        margin-right: 14px;
      }
    }

    &__user-id {
      font-size: 12px;
      margin-left: 15px;
      padding: 3px 6px;
      border: 1px solid #e5e5e5;
      border-radius: 5px;

      &_loading {
        width: 56px;
      }
    }
  }
}

.question-body {
  margin-left: 30px;

  &__content {
    font-size: 16px;
    padding-top: 10px;

    &_loading {
      width: 790px;
      margin-top: 19px;
    }
  }

  &__buttons {
    padding-top: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &_loading {
      display: flex;
      margin-top: 20px;

      .button-first {
        width: 101px;
        margin-right: 10px;
      }
      .button-second {
        width: 208px;
        margin-right: 10px;
      }
      .button-third {
        width: 183px;
      }
    }

    .buttons-group {
      display: flex;

      ::v-deep {
        .zeen-button {
          color: #222;
          font-size: 12px;
          margin-right: 10px;
          border: 1px solid #e5e5e5;
          padding: 10px 12px;
          height: min-content;

          span {
            margin-left: 10px;
          }
          .icon {
            font-size: 16px;
          }
          .icon-reply {
            color: #a672ff;
          }
          .icon-block {
            color: #ff4d4d;
          }
          .icon-lock {
            color: #ff4d4d;
          }
          .icon-unlock {
            color: #00943e;
          }
          .icon-basket {
            color: #a672ff;
            font-size: 14px;
            font-weight: 600;
          }
          .icon-check-mark {
            color: #00943e;
          }
          .icon-not-answered {
            color: #000;
          }
          .icon-answered {
            color: #a672ff;
          }
        }
      }
    }

    .button-likes {
      color: #222;
      font-size: 12px;

      .icon-heart-stroke {
        font-size: 15px;
        padding-right: 5px;
      }

      &_no-likes {
        color: #999;
        .icon-heart {
          color: #f2f2f2;
          font-size: 15px;
          padding-right: 5px;
        }
      }
    }
  }

  .to-answer {
    padding-left: 10px;
    border-left: 2px solid #a672ff;
    margin: 10px 0;
    font-size: 12px;

    &__author {
      font-weight: 600;
    }
  }
}

.secondary-info {
  font-size: 12px;
  position: relative;
  display: flex;

  &__title {
    color: #999;
    width: min-content;

    span {
      width: min-content;
    }

    &_framed {
      position: relative;
      color: #999;
      padding-right: 12px;
      margin-right: 12px;
      border-right: 1px solid #e5e5e5;
    }
  }
  &__stream-name {
    width: 150px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    display: inline-block;
    vertical-align: middle;
    &:hover {
      cursor: default;
      ~ .stream-tooltip {
        ::v-deep .tooltip {
          display: flex;
          position: absolute;
          left: 0;
          bottom: 10px;
        }
      }
    }
  }

  &__speech-name {
    &:hover {
      cursor: default;
      ~ .speech-tooltip {
        ::v-deep .tooltip {
          display: flex;
          position: absolute;
          left: 0;
          bottom: 10px;
        }
      }
    }
  }
}
</style>