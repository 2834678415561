export default {
  "auth": {
    "title": "Sign in",
    "login": "Email",
    "password": "Password",
    "password_forgot": "Forgot your password?",
    "login_btn": "Log in",
  },

  "room_select": {
    "logout_btn": "Back",
    "tabs": {
      "chat": "Chats",
      "questions": "Questions for speakers",
      "all_rooms": "All rooms"
    },
  },

  "chat": {
    "back_btn": "Back",
    "logout_btn": "Log out",
    "write_message_btn": "Write a message",
    "all_speakers": "All speakers",
  },

  "questions": {
    "back_btn": "Back",
    "logout_btn": "Back",
    "": "",
  },

  "side_panel_rooms": {
    "day_label": "Days",
    "day_select": "Choose the day...",
    "stream_label": "Halls",
    "stream_select": "Choose the hall...",
    "speach_label": "Speech",
    "speach_select": "Choose the speech...",
    "reset": "Reset"
  },

  "side_panel_questions": {
    "search_title": "Search for text or authors",
    "search_placeholder": "Search for...",
    "speaker_label": "Chose the speaker",
    "speaker_placeholder": "Speaker...",
    "filter_list": {
      "title": "Status",
      "all": "all questions",
      "approved": "published",
      "rejected": "rejected",
      "pending": "pending",
    },
    "sorting_list": {
      "title": "Likes",
      "all": "no filter",
      "asc": "A-Z",
      "desc": "Z-A",
    },
    "buttons": {
      "export_excel": "Export in Excel",
    },
  },

  "side_panel_chat": {
    "search_label": "Search for text or authors",
    "search_placeholder": "Search for...",
    "filter_list": {
      "title": "Status",
      "all": "All messages",
      "approved": "published",
      "rejected": "rejected",
    },
    "buttons": {
      "export_excel": "Export in Excel",
    },
  },

  "selection_card": {
    "router_btn": "Go",
    "types": {
      "chat": "Chat",
      "questions": "Question to speakers",
    },
  },

  "question": {
    "buttons": {
       "answer": "Answer",
       "reject": "Reject",
       "post": "Publish",
       "ban": "Block the author",
       "unban": "Unblock the author",
       "delete": "Delete",
    },
  },

  "message": {
    "buttons": {
       "answer": "Answer",
       "reject": "Reject",
       "post": "Publish",
       "ban": "Block the author",
       "unban": "Unblock the author",
       "delete": "Delete",
    },
  },

  "ui": {
    "input_file": {
      "text": "Attach an image",
      "label": "Attached an image",
      "text_for_btn": "Replace an image",
      "requirements": "JPEG or PNG, no more than 1 MB",
      "img_alt": "Image",
    },
    "tooltips": {
      "unban_autor": "First you need to unblock the author",
    },
    "select_lang": {
      "label": "Language"
    }
  },

  "modals": {
    "write_message": {
      "title": "Write a message",
      "description": "Message text",
      "placeholder": "Enter the text...",
      "input_file_text": "Attach an image",
      "input_file_label": "Attached image",
      "input_file_btn": "Replace an image",
      "send_btn": "Send",
      "cancel_btn": "Cancel",
      "error_extension": "The file does not match the format (jpeg, jpg, png)",
      "error_limit": "The file size cannot exceed 10MB!",
    },
    "reply_to_message": {
      "title": "Reply to message",
      "description": "Message text",
      "answer_description": "Answer text",
      "placeholder": "Enter the text...",
      "input_file_text": "Attach an image",
      "input_file_label": "Attached image",
      "input_file_btn": "Replace an image",
      "send_btn": "Send",
      "cancel_btn": "Cancel",
    },
    "reply_to_question": {
      "title": "Reply to message",
      "description": "Question",
      "answer_description": "Answer text",
      "placeholder": "Enter the text...",
      "is_ptivate_title": "Private",
      "send_btn": "Send",
      "cancel_btn": "Cancel",
    },
  },

  "notifications": {
    "all_answers_reviewed": "You have seen all the answers",
    "all_messages_reviewed": "You have viewed all messages",
    "question_rejected": "Question rejected",
    "question_approved": "Question published",
    "message_rejected": "Message rejected",
    "message_approved": "Message published",
    "error": "Error",
    "user_was_banned": "The user is blocked",
    "user_was_unbanned": "The user is unlocked",
    "successfully_sent": "Sent",
  }
}