import zeenApi from '../../api/api'
import router from '../../router/router'


const user = {
  namespaced: true,

  state: {
    user: {},
    token: null,
    companies: []
  },

  mutations: {
    updateCredentials(state, {token, user}) {
      if (token) {
        state.token = token
        zeenApi.setUpToken(token)
      }
      if (user) {
        state.user = user
      }
    },
    clearCredentials(state) {
      state.token = null
      state.user = {}
    },
    updateToken(token) {
      zeenApi.setUpToken(token.token)
    },
  },

  actions: {
    login({commit}, credentials) {
      return new Promise((resolve, reject) => {
        const catchFn = (error) => {
          reject(error)
        }

        zeenApi.project['admin.login'](credentials)
          .then((data) => {
            commit('updateCredentials', data)
            resolve(data)
          })
          .catch(catchFn)
      })
    },
    logout({commit}) {
      commit('clearCredentials')
      zeenApi.clearToken()
      router.push({name: 'auth'})
    }
  },
  getters: {
    user: (state) => state.user,
    token: (state) => state.token,
    userId:(state) => state.user.id,
    isAuth: (state) => !!state.token,
  },
}

export default user
