import layouts from 'convert-layout'

export const searchToArray = (search) => {
  search = search.trim().toLowerCase()
  let searchArray = Object.values(layouts).map(({fromEn, toEn}) => [fromEn(search), toEn(search)]).flat(2)

  return searchArray
}

export const searchInArrayFields = (search, findFields = [], items = []) => {
  const searchArray = searchToArray(search)

  return items.filter((item) => {
    return findFields.some(key => {
      let field = item[key]

      if (!field) {
        return false
      }

      field = field.trim().toLowerCase()
      return searchArray.some(searchLocal => field.indexOf(searchLocal) >= 0)
    })
  })
}
