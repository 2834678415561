<template lang="pug">
  .select-lang(v-click-outside='closeDropdown')
    .select-button(@click.prevent='toggleDropdown')
      .icon-langs
      span {{$t('ui.select_lang.label')}} 
      span.select-button__current-lang {{currentLabel}}
    .dropdown(v-if='showDropdown')
      vueCustomScrollbar.dropdown__scroll
        .dropdown__items
          .dropdown__item(
            v-for='item in langs'
            :key='item.key'
            @click.prevent='chooseLang(item.label)') {{item.title}}
</template>

<script>
import ClickOutside from '../../directives/click-outside'
import vueCustomScrollbar from 'vue-custom-scrollbar'

export default {
  components: {
    vueCustomScrollbar
  },
  data() {
    return {
      showDropdown: false
    }
  },
  methods: {
    toggleDropdown() {
      this.showDropdown = !this.showDropdown
    },
    closeDropdown() {
      this.showDropdown = false
    },
    chooseLang(lang) {
      this.$store.commit('lang/setLang', lang)
      this.showDropdown = false
    },
  },
  computed: {
    langs() {
      return this.$store.getters['lang/availableLangs']
    },
    currentLang() {
      return this.$store.state['lang']
    },
    currentLabel() {
      const langObj = this.langs.filter((lang) => {
        return lang.key === this.$i18n.locale
      })

      return langObj[0] ? langObj[0].label : null
    }
  },
  directives: {
    ClickOutside,
  },
}
</script>

<style scoped lang="scss">
  .select-lang {
    position: relative;
  }

  .select-button {
    display: flex;
    align-items: center;
    color: #fff;
    cursor: pointer;

    span {
      padding-left: 10px;
    }

    &__current-lang {
      color: #999;
    }
  }

  .icon {
    fill: #fff;
    width: 16px;
  }

  .dropdown {
    position: absolute;
    bottom: 30px;
    left: 30px;
    min-width: 191px;
    border-radius: 10px;
    overflow: hidden;

    &__scroll {
      max-height: 110px;
    }

    &__item {
      font-size: 14px;
      padding: 10px 20px;
      display: flex;
      justify-content: flex-start;
      background: #343434;
      color: #fff;
      cursor: pointer;

      &:hover {
        background: #444;
      }

      &:first-of-type {
        border-radius: 10px 10px 0 0;
        padding: 20px 20px 10px;
      }

      &:last-of-type {
        border-radius: 0 0 10px 10px;
        padding: 10px 20px 20px;
      }
    }
  }

  ::v-deep {
    .ps__rail-y {
      background-color: transparent !important;
      margin-top: 20px;
    }

    .ps__thumb-y {
      background: #999 !important;
      width: 4px !important;
      height: 42px !important;
    }
  }
</style>
