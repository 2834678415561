import { Centrifuge } from 'centrifuge';
import {WEBSOCKET_URL} from '../api/config'

class CentrifugeSocket {
  constructor() {
    this.isConnected = false;
    this.connection = null;
    this.userCode = null;
    this.centrifuge = new Centrifuge(WEBSOCKET_URL);
    this.subscriptionParams = {};
    this.events = {};
    this.eventsStore = {};
  }

  connect({ centrifugeToken, userCode }) {
    // this.disconnect();
    if (!this.isConnected){
      this.userCode = userCode;
      this.centrifuge.setToken(centrifugeToken);
      this.centrifuge.connect();
      this.setupCallbacks();
    }
  }

  disconnect() {
    this.centrifuge.removeAllListeners();
    this.centrifuge.disconnect();
  }

  setupCallbacks() {
    this.centrifuge.on('connect', this.onConnect.bind(this));
    this.centrifuge.on('disconnect', this.onDisconnect.bind(this));
    window.onbeforeunload = this.onBeforeUnload.bind(this);
  }

  onConnect(ctx) {
    this.isConnected = true;
    console.log(ctx)
    // Handle connection
  }

  onDisconnect(ctx) {
    this.isConnected = false;
    console.log(ctx)
    // Handle disconnection
  }

  onBeforeUnload() {
    if (this.connection) {
      this.connection.close();
    }
  }
  subscribe(channel = null, params = {}) {
    const conferenceChannel = channel;
    const subscribedChannels = this.centrifuge.subscriptions();
  
    if (!(conferenceChannel in subscribedChannels)) {
      const sub = this.centrifuge.newSubscription(conferenceChannel);
      sub.on('publication', ctx => this.handlePublication(ctx, conferenceChannel));
      sub.subscribe();
    } else if (conferenceChannel in subscribedChannels) {
      if (subscribedChannels[conferenceChannel].state === "unsubscribed") {
        subscribedChannels[conferenceChannel].subscribe();
      }
    }
  
    this.subscriptionParams[conferenceChannel] = params;
  }

  unsubscribe(channel = null) {
    const conferenceChannel = channel;
    const subscribedChannels = this.centrifuge.subscriptions();
    // Проверяем, существует ли подписка на этот канал
    if (conferenceChannel in subscribedChannels) {
      // Выполняем отписку от канала
      subscribedChannels[conferenceChannel].unsubscribe();
    }
  }
  
  handlePublication(ctx, channel) {
    const { event, data, ...anydata } = ctx.data;
    if (event) {
      this.fire(event, {
        ...data,
        ...anydata,
        data_params: this.subscriptionParams[channel],
      });
    }
  }

  resetListeners() {
    this.events = {};
  }

  on(event_name, cb) {
    let current_data = this.events[event_name];

    if (!current_data) {
      current_data = [];
    }

    current_data.push(cb);
    this.events[event_name] = current_data;
  }

  onDeep(event_name, cb) {
    const current_data = this.eventsStore?.[event_name] ?? [];
    current_data.push(cb);
    this.eventsStore[event_name] = current_data;
  }

  offDeep(event_name, cb) {
    let current_data = this.eventsStore[event_name];

    if (current_data) {
      for (let i = 0; i < current_data.length; i++) {
        if (current_data[i] === cb) {
          current_data.splice(i, 1);
        }
      }
    }

    this.eventsStore[event_name] = current_data;
  }

  off(event_name, cb) {
    let current_data = this.events[event_name];

    if (current_data) {
      for (let i = 0; i < current_data.length; i++) {
        if (current_data[i] === cb) {
          current_data.splice(i, 1);
        }
      }
    }

    this.events[event_name] = current_data;
  }

  fire(event_name, data) {
    let current_data = [...(this.events?.[event_name] || []), ...(this.eventsStore?.[event_name] || [])];

    if (!current_data) return;

    for (let i = 0; i < current_data.length; i++) {
      current_data[i](data);
    }
  }
}

export default new CentrifugeSocket();
