<template lang="pug">
  .selection-cards(:class="classCards")
    SelectionCard(
      v-for="(chatRoom, index) in chatRooms" 
      :key='index'
      :speech='chatRoom.speech'
      :stream='chatRoom.stream'
      :id='chatRoom.id'
      :type='chatRoom.room_type')
</template>

<script>
import SelectionCard from './SelectionCard';
export default {
  name: "SelectionCards",
  components: {SelectionCard},
  props: {
    chatRooms: {
      default: () => [],
      type: Array
    }
  },
  computed: {
    classCards() {
      if(this.chatRooms.length === 2) {
        return 'selection-cards_two-cards'
      } else if(this.chatRooms.length > 2) {
        return 'selection-cards_more-cards'
      } else return ''
    },
  }
}
</script>

<style scoped lang="scss">
  .selection-cards {
    margin: 0 auto;
    padding-top: 40px;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 20px;

    &_two-cards {
      grid-template-columns: repeat(2, 1fr);
    }

    &_more-cards {
      grid-template-columns: repeat(3, 1fr);
    }
  }
</style>