<template lang='pug'>
.auth-page
  form.auth-page__form(@submit.prevent='sendData')
    h2.auth-page__title {{$t(`auth.title`)}}
    .auth-page__row
      ZeenTextInput(
        v-model='authData.email'
        :label='$t(`auth.login`)'
      )
    .auth-page__row
      .auth-page__label-header 
        span.auth-page__label {{$t(`auth.password`)}}
        a.auth-page__forget {{$t(`auth.password_forgot`)}}
      ZeenTextInput(
        v-model='authData.password',
        :type='showPassword ? "text" : "password"'
      )
      a.auth-page__icon-show(
        @click='showPassword = !showPassword'
        :class='{"icon-no-show": !showPassword, "icon-show": showPassword}'
      )
    .auth-page__footer
      .auth-page__error {{error}}
      ZeenButton(:loading='isLoading') {{$t(`auth.login_btn`)}}
</template>

<script>
export default {
  name: 'Auth',
  data() {
    return {
      isLoading: false,
      showPassword: false,
      authData: {
        email: process.env.NODE_ENV === 'development' ? 'dev@example.com' : '',
        password: process.env.NODE_ENV === 'development' ? 'example' : '',
      },
      error: ''
    }
  },

  methods: {
    sendData() {
      if (this.isLoading) {
        return
      }
      if (!this.canSubmit) {
        return
      }
      this.isLoading = true
      this.authData.email = this.authData.email.trim().toLowerCase()
      
      this.$store.dispatch('user/login', this.authData)
        .then(() => {
          this.$router.push('/')
        })
        .catch((data) => {
          this.error = `${this.$t('notifications.error')}: ${data?.data?.message}`
        })
        .finally(() => {          
          this.isLoading = false
        })
    },
  },
  computed: {
    canSubmit() {
      return this.authData.email && this.authData.password
    },
  },
}
</script>

<style scoped lang='scss'>

.auth-page {
  flex: 1 1 auto;
  display: flex;
  background: #f5f5f5;
  width: 100vw;
  height: 100vh;
  align-items: center;
  flex-direction: column;

  &__form {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto 0;
    padding: 0 70px;
    background: white;
    border-radius: 10px;
    max-height: 478px;
    max-width: 430px;
    height: 100%;
    width: 100%;
  }

  &__title {
    margin: 60px 0 20px 0;
    font-family: sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
    color: #222222;
    text-align: center;
  }

  &__row {
    width: 100%;
    margin-top: 20px;
    position: relative;
  }

  &__label {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #999;
  }

  &__label-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 6px;
  }

  &__forget {
    color: #a672ff;
    text-decoration: none;
    font-size: 14px;
  }

  &__icon-show {
    color: #999;
    position: absolute;
    right: 18px;
    font-size: 18px;
    bottom: 18px;
  }
  &__footer {
    margin-top: 40px;
    width: 100%;

    .zeen-button {
      height: 55px;
      width: 100%;
      font-family: sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 19px;
    }
  }

  &__error {
    color: red;
    font-size: 12px;
  }
}
</style>
