<template lang="pug">
  ZeenModal(
    contentClass='modal-content',
    name='reply-to-question-modal',
    v-model='show'
  )
    template(v-slot='{params}')
      ReplyToQuestionForm(
        :isOpen='show'
        :data='params.data'
      )
</template>

<script>
import ZeenModal from '../ui/ZeenModal';
import ReplyToQuestionForm from './forms/ReplyToQuestionForm';

export default {
  name: 'ReplyToQuestionModal',
  components: {
    ReplyToQuestionForm,
    ZeenModal,
  },
  data() {
    return {
      show: false
    }
  }
}
</script>