import ZeenApi from 'zeen-api'
//import ZeenApi from '../../../../zeen/zeen-api'
import { BASE_URL } from './config'

const zeenApi = new ZeenApi({
  conferenceCode: '',
  baseURL: BASE_URL,
})

const adminLogin = (axiosInstance) => {
  // обертка для получения инстанса axios
  return (params) => {
    // непосредственно вызываемая функция
    return axiosInstance.post('/comments/api/admin/sign_in', params)
  }
}
zeenApi.addProjectApiFunction('admin.login', adminLogin)

const loadConference = (axiosInstance) => {
  return (conference_id) => {
    return axiosInstance.get(`/core/api/admin/conferences/${conference_id}`, {
      params: {
        conference_id,
      },
    })
  }
}
zeenApi.addProjectApiFunction('admin.conference.load', loadConference)

const adminConferencesStreams = (axiosInstance) => {
  return (params) => {
    return axiosInstance.get('/core/api/admin/streams', { params })
  }
}
zeenApi.addProjectApiFunction('admin.conference.streams', adminConferencesStreams)

const adminConferencesDays = (axiosInstance) => {
  return (params) => {
    return axiosInstance.get('/core/api/admin/conference_days', { params })
  }
}
zeenApi.addProjectApiFunction('admin.conferences.days', adminConferencesDays)


const adminConferencesSpeeches = (axiosInstance) => {
  return (stream_id) => {
    return axiosInstance.get(`/core/api/admin/speeches`, {
      params: {
        stream_id,
      },
    })
  }
}

zeenApi.addProjectApiFunction('admin.conference.speeches', adminConferencesSpeeches)


const adminChatRooms = (axiosInstance) => {
  return (params) => {
    return axiosInstance.get('/comments/api/admin/chat_rooms', {params})
  }
}
zeenApi.addProjectApiFunction('admin.chatrooms.load', adminChatRooms)

const adminChats = (axiosInstance) => {
  return (roomId) => {
    return axiosInstance.get(`/comments/api/admin/chat_rooms/${roomId}/chats`, {chat_room_id: roomId})
  }
}
zeenApi.addProjectApiFunction('admin.chats.load', adminChats)

const adminChatsSend = (axiosInstance) => {
  return (data, chat_room_id) => {
    return axiosInstance.post(`/comments/api/admin/chat_rooms/${chat_room_id}/chats`, data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
  }
}
zeenApi.addProjectApiFunction('admin.chats.send', adminChatsSend)

const adminChatsReply = (axiosInstance) => {
  return (data, id) => {
    return axiosInstance.post(`/comments/api/admin/chats/${id}/reply`, data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
  }
}
zeenApi.addProjectApiFunction('admin.chats.reply', adminChatsReply)


const adminExport = (axiosInstance) => {
  return (roomId) => {
    return axiosInstance.get(`/comments/api/admin/chat_rooms/${roomId}/export`,
      {
        responseType: 'blob', // important
      },
    )
  }
}
zeenApi.addProjectApiFunction('admin.export', adminExport)

const adminRejectChats = (axiosInstance) => {
  return (id) => {
    return axiosInstance.post(`/comments/api/admin/chats/${id}/reject`, {id})
  }
}
zeenApi.addProjectApiFunction('admin.chats.reject', adminRejectChats)

const adminApproveChats = (axiosInstance) => {
  return (id) => {
    return axiosInstance.post(`/comments/api/admin/chats/${id}/approve`, {id})
  }
}
zeenApi.addProjectApiFunction('admin.chats.approve', adminApproveChats)

const adminUserBan = (axiosInstance) => {
  return (id) => {
    return axiosInstance.post(`/comments/api/admin/chats/${id}/user_ban`, {id})
  }
}
zeenApi.addProjectApiFunction('admin.user.ban', adminUserBan)

const adminUserUnban = (axiosInstance) => {
  return (id) => {
    return axiosInstance.post(`/comments/api/admin/chats/${id}/user_unban`, {id})
  }
}
zeenApi.addProjectApiFunction('admin.user.unban', adminUserUnban)

const adminQuestions = (axiosInstance) => {
  return (roomId) => {
    return axiosInstance.get(`/comments/api/admin/chat_rooms/${roomId}/questions`, {chat_room_id: roomId})
  }
}
zeenApi.addProjectApiFunction('admin.questions.load', adminQuestions)

const adminQuestionsReply = (axiosInstance) => {
  return (params) => {
    return axiosInstance.post(`/comments/api/admin/questions/${params.id}/reply`, params)
  }
}
zeenApi.addProjectApiFunction('admin.questions.reply', adminQuestionsReply)

const adminRejectQuestion = (axiosInstance) => {
  return (id) => {
    return axiosInstance.post(`/comments/api/admin/questions/${id}/reject`, {id})
  }
}
zeenApi.addProjectApiFunction('admin.question.reject', adminRejectQuestion)

const adminApproveQuestion = (axiosInstance) => {
  return (id) => {
    return axiosInstance.post(`/comments/api/admin/questions/${id}/approve`, {id})
  }
}
zeenApi.addProjectApiFunction('admin.question.approve', adminApproveQuestion)


const adminQuestionUserBan = (axiosInstance) => {
  return (id) => {
    return axiosInstance.post(`/comments/api/admin/questions/${id}/user_ban`, {id})
  }
}
zeenApi.addProjectApiFunction('admin.question.user.ban', adminQuestionUserBan)

const adminQuestionUserUnban = (axiosInstance) => {
  return (id) => {
    return axiosInstance.post(`/comments/api/admin/questions/${id}/user_unban`, {id})
  }
}
zeenApi.addProjectApiFunction('admin.question.user.unban', adminQuestionUserUnban)


const adminQuestionAnswered = (axiosInstance) => {
  return (id) => {
    return axiosInstance.post(`/comments/api/admin/questions/${id}/answered`, {id})
  }
}
zeenApi.addProjectApiFunction('admin.question.answered', adminQuestionAnswered)


const adminPushAuthorizations = (axiosInstance) => {
  return () => {
    return axiosInstance.post(`comments/api/admin/push_authorizations`)
  }
}
zeenApi.addProjectApiFunction('admin.authorizations', adminPushAuthorizations)

export default zeenApi
