import Vue from 'vue'
import App from './scripts/App.vue'
import router from './scripts/router/router'
import VueFinalModal from 'vue-final-modal'
import Notifications from 'vue-notification'

import UiAdmin from 'ui-admin'
import zeenApi from './scripts/api/api'
import store from './scripts/store'
import i18n from './scripts/locales'

import './styles/styles.scss'

if (store.getters.token) {
  store.commit('user/updateToken', store.getters.token)
}

if (store.getters.lang) {
  store.commit('lang/setLang', store.getters.lang)
}

Vue.use(UiAdmin)
Vue.use(VueFinalModal())
Vue.use(Notifications)

Vue.config.productionTip = false
Vue.prototype.$api = zeenApi

new Vue({
  render: h => h(App),
  router,
  store,
  i18n,
}).$mount('#app')
