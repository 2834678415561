import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersistence from 'vuex-persist'
import conference from './modules/conference'
import user from './modules/user'
import chatrooms from './modules/chatrooms'
import chats from './modules/chats'
import questions from './modules/questions'
import lang from './modules/lang'

Vue.use(Vuex)

const store = new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  getters: {
    isAuth: (state, getters) => getters['user/isAuth'],
    token: (state, getters) => getters['user/token']
  },
  modules: {
    conference,
    user,
    chatrooms, 
    chats,
    questions,
    lang
  },
  plugins: [
    new VuexPersistence({
      key: 'zeen-admin-user',
      modules: [
          'user',
          'chatrooms',
          'chats',
          'questions'
      ],
    }).plugin,
  ]
})

export default store
