<template lang='pug'>
  notifications(
    position="bottom center"
  )
    template(slot="body" slot-scope="props")
      .zeen-notification
        .zeen-notification__icon
          .icon-check-mark(
            v-if="props.item.type === 'success'"
          )
        .zeen-notification__body
          .zeen-notification__title {{props.item.title}}
          .zeen-notification__text(v-html="props.item.text")
</template>

<script>
export default {
  name: 'NotificationsComponent',
}
</script>

<style scoped lang='scss'>
.zeen-notification {
  display: flex;
  align-items: center;
  font-size: 16px;
  color: #999;
  margin: 50px 0 70px;

  &__icon {
    color: #a672ff;
    font-size: 25px;
    margin-right: 12px;
  }

}
</style>
