<template lang="pug">
  .room-select
    SidePanelRooms(@filter='filterChange')
    .main-content
      .main-content__header 
        ZeenTabs.main-content__tabs(
          :value="currentTab"
          :list="tabs"
          v-model="currentTab")
        ZeenButton(
          theme="string"
          @click.revent="logout")
          .icon-upload
          p {{$t(`room_select.logout_btn`)}}
      SelectionCards(:chatRooms='chatRoomsFilteredSelect')
</template>

<script>
import SidePanelRooms from '../components/layout/SidePanelRooms';
import SelectionCards from '../components/room-select/SelectionCards';

export default {
  name: 'RoomSelect',
  components: { SelectionCards, SidePanelRooms },
  data() {
    return {
      filterSelect: null,
      currentTab: 'allRooms',
    };
  },
  mounted() {
    this.$store.dispatch('chatrooms/loadChatrooms');
  },
  methods: {
    filterChange(value) {
      this.filterSelect = value;
    },
    logout() {
      this.$store.dispatch('user/logout');
    },
  },
  computed: {
    tabs() {
      return [
        { value: 'allRooms', label: this.$t(`room_select.tabs.all_rooms`)},
        { value: 'chat', label: this.$t(`room_select.tabs.chat`) },
        { value: 'questions', label: this.$t(`room_select.tabs.questions`) }
      ]
    },
    chatRooms() {
      return this.$store.getters['chatrooms/getChatrooms'];
    },
    chatRoomsFilteredTab() {
      if (this.currentTab === 'allRooms') {
        return this.chatRooms;
      }
      return this.$store.getters['chatrooms/getChatrooms'].filter(
        (room) => room.room_type === this.currentTab
      );
    },
    chatRoomsFilteredSelect() {
      if (this.filterSelect?.day) {
        return [...this.chatRoomsFilteredTab].filter(
          (room) => room.conference_day?.id === this.filterSelect.day
        );
      }
      if (this.filterSelect?.stream) {
        return [...this.chatRoomsFilteredTab].filter(
          (room) => room.stream?.id === this.filterSelect.stream
        );
      }
      if (this.filterSelect?.speech) {
        return [...this.chatRoomsFilteredTab].filter(
          (room) => room.speech?.id === this.filterSelect.speech
        );
      }
      return this.chatRoomsFilteredTab;
    },
  },
};
</script>

<style scoped lang="scss">
.room-select {
  display: flex;
  margin: 0 20px 20px 20px;
}
.main-content {
  width: 100%;
  height: 100%;
  padding-left: 347px;
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .icon-upload {
      transform: rotate(90deg);
      margin-right: 10px;
    }
  }
}

::v-deep .zeen-tabs__item {
  width: 156px;
  margin-right: 0 !important;
}
</style>
