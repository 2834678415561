import zeenApi from '../../api/api'

const chatrooms = {
  namespaced: true,

  state: {
    chatrooms: [],
    days: [],
    streams: [],
    speeches: []
  },

  mutations: {
    saveChatrooms(state, chatrooms) {
      state.chatrooms = chatrooms
    },
    saveDays(state, chatrooms) {
      chatrooms.forEach(day => {
        if (!state.days.some(item => item.id === day.conference_day.id)) {
          state.days.push(day.conference_day)
        }
      })
    },
    saveStreams(state, chatrooms) {
      chatrooms.forEach(stream => {
        if (!state.streams.some(item => item.id === stream.stream.id)) {
          state.streams.push(stream.stream)
        }
      })
    },  
    saveSpeach(state, chatrooms) {
      chatrooms.forEach(speech => {
        if (!state.speeches.some(item => item.id === speech.speech.id)) {
          state.speeches.push(speech.speech)
        }
      })
    },   
  },

  actions: {
    loadChatrooms({ commit }, params) {
      return zeenApi.project['admin.chatrooms.load'](params).then((data) => {
        commit('saveChatrooms', data.chat_rooms)
        commit('saveDays', data.chat_rooms)
        commit('saveStreams', data.chat_rooms)
        commit('saveSpeach', data.chat_rooms)
      })
    },
  },
  getters: {
    getChatrooms: (state) => state.chatrooms,
    getChatroomById: (state) => (id) => {
      return state.chatrooms.find(room => room.id === id)
    },
    getDays: (state) => state.days,
    getStreams: (state) => state.streams,
    getSpeeches: (state) => state.speeches,
    
  }
}

export default chatrooms
