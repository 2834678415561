<template lang="pug">
  ZeenModal(
    contentClass='modal-content',
    name='reply-to-message-modal',
    v-model='show'
  )
    template(v-slot='{params}')
      ReplyToMessageForm(
        :isOpen='show'
        :data='params.data'
      )
</template>

<script>
import ZeenModal from '../ui/ZeenModal';
import ReplyToMessageForm from './forms/ReplyToMessageForm';

export default {
  name: 'ReplyToMessageModal',
  components: {
    ZeenModal,
    ReplyToMessageForm
  },
  data() {
    return {
      show: false
    }
  }
}
</script>