export const isProduction = window.location.host === 'admin-moderation.pro.zeenevents.com'
// export const isProduction = true

export const useOnlyDefaultLang = true

const configuration = {
  development: {
    BASE_DOMAIN: 'nokube.zeen.club',
    BASE_URL: 'https://nokube.zeen.club',
    WEBSOCKET_URL: 'wss://centrifugo.admin.zeen.club/connection/websocket',
  },
  production: {
    BASE_DOMAIN: 'nokube.zeen.club',
    BASE_URL: 'https://nokube.zeen.club',
    WEBSOCKET_URL: 'wss://centrifugo.admin.zeen.club/connection/websocket',
  },
}

export const config = configuration[isProduction ? 'production' : 'development']

export const {
  BASE_DOMAIN,
  BASE_URL,
  WEBSOCKET_URL,
} = configuration[isProduction ? 'production' : 'development']
