<template>
  <svg :width="width" :height="height" viewBox="0 0 54 14" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M51.2009 7.13091V0L54 2.59519V11.9922H52.0396L44.436 4.84321V12L41.6697 9.38794V0H43.5749L51.2009 7.13091Z" :fill="color"/>
  <path d="M33.9671 11.9999C32.9999 11.9999 32.1218 11.8538 31.3328 11.5615C30.5791 11.2894 29.8903 10.8843 29.3051 10.3688C28.7406 9.86715 28.2959 9.26245 27.9994 8.59334C27.6911 7.89962 27.5353 7.15636 27.541 6.40669V5.97855C27.541 5.09466 27.692 4.28727 27.9939 3.55632C28.2737 2.85635 28.702 2.21469 29.2535 1.66871C29.797 1.13996 30.4539 0.721077 31.1823 0.438624C31.9497 0.141829 32.7748 -0.00733708 33.607 0.000277503C34.5123 0.000277503 35.3189 0.13752 36.0267 0.412006C36.7117 0.671989 37.3259 1.06824 37.8253 1.57243C38.3309 2.09451 38.7122 2.70838 38.9449 3.37452C39.2095 4.12542 39.3398 4.911 39.3307 5.70048V6.96227H30.8105C30.8664 7.33867 30.9942 7.703 31.1878 8.03833C31.5592 8.68269 32.1776 9.17656 32.9225 9.42357C33.3208 9.55479 33.7413 9.61995 34.1643 9.61616C34.4818 9.61621 34.7986 9.58758 35.1099 9.53065C35.4181 9.47493 35.7192 9.39068 36.0089 9.27915C36.2913 9.17039 36.5593 9.03224 36.8077 8.86743C37.06 8.68929 37.2842 8.47961 37.4738 8.24449L39.1943 9.75038C38.929 10.0851 38.6254 10.3925 38.2886 10.6673C37.9424 10.9354 37.5603 11.1617 37.1518 11.3407C36.686 11.5464 36.1979 11.706 35.6955 11.8169C35.1295 11.9419 34.5491 12.0034 33.9671 11.9999ZM33.5842 2.40665C33.2421 2.40289 32.9027 2.46279 32.5864 2.58278C32.2859 2.70007 32.0151 2.87364 31.7913 3.09249C31.5543 3.32626 31.3602 3.59406 31.2167 3.88536C31.0619 4.19175 31.0324 4.29709 30.9556 4.67484H36.2468H36.1601C36.1469 4.37113 36.0803 4.0714 35.9629 3.78739C35.8523 3.51756 35.6825 3.27172 35.4639 3.0653C35.2375 2.85625 34.9668 2.69239 34.6688 2.58392C34.3231 2.46076 33.9548 2.40057 33.5842 2.40665Z" :fill="color"/>
  <path d="M20.0169 11.9998C19.0495 11.9998 18.1712 11.8537 17.382 11.5615C16.6281 11.2894 15.9391 10.8842 15.3538 10.3688C14.7891 9.86713 14.3443 9.26243 14.0477 8.59332C13.7393 7.89961 13.5835 7.15635 13.5892 6.40668V5.97854C13.5892 5.09465 13.7402 4.28726 14.0422 3.55631C14.3236 2.85597 14.7536 2.21429 15.3071 1.66871C15.8493 1.14047 16.5045 0.721618 17.2314 0.438623C17.999 0.141828 18.8243 -0.00733706 19.6567 0.000277502C20.5618 0.000277502 21.3686 0.13752 22.0771 0.412005C22.7622 0.671988 23.3765 1.06824 23.8761 1.57243C24.3818 2.0945 24.7633 2.70838 24.9959 3.37452C25.2596 4.12557 25.3889 4.91113 25.3788 5.70047V6.96225H16.8577C16.9137 7.33865 17.0414 7.70298 17.2351 8.03831C17.6066 8.68268 18.2251 9.17654 18.9702 9.42355C19.3687 9.55477 19.7892 9.61994 20.2124 9.61615C20.5306 9.61591 20.848 9.58691 21.1601 9.52946C21.4682 9.47379 21.7695 9.38954 22.0593 9.27801C22.3417 9.1692 22.6098 9.0311 22.8583 8.86629C23.1042 8.70373 23.3232 8.50916 23.5085 8.28864L25.2442 9.75149C24.9789 10.0862 24.6751 10.3936 24.3383 10.6684C23.992 10.9365 23.6098 11.1629 23.2012 11.3418C22.7353 11.5475 22.247 11.7071 21.7446 11.818C21.1787 11.9426 20.5986 12.0036 20.0169 11.9998ZM19.634 2.40665C19.2918 2.40288 18.9523 2.46279 18.6358 2.58278C18.3353 2.70006 18.0644 2.87364 17.8405 3.09248C17.6035 3.32626 17.4094 3.59406 17.2659 3.88535C17.1109 4.19174 17.0815 4.29708 17.0047 4.67483H22.2971H22.2105C22.1973 4.37112 22.1307 4.07139 22.0132 3.78738C21.9026 3.51755 21.7327 3.27171 21.5141 3.0653C21.2876 2.85625 21.017 2.69238 20.7188 2.58391C20.373 2.46076 20.0046 2.40056 19.634 2.40665Z" :fill="color"/>
  <path d="M7.13524 2.62353H0.0715255L2.57193 0H11.8803V1.9076L4.76835 9.35711H11.8577L9.30065 12H0V10.1482L7.13524 2.62353Z" :fill="color"/>
  </svg>
</template>
<script>
export default {
  name: 'ZeenLogo',
  props: {
    color: {
      type: String,
      default: () => '#fff'
    },
    width: {
      type: Number,
      default: () => 54
    },
    height: {
      type: Number,
      default: () => 14
    }
  }
}
</script>