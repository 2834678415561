<template lang="pug">
  .selection-card(
    :class="cardClass"
  )
    p.selection-card__title {{stream.name}}
      span(v-if="speech.title")  /  {{speech.title}}
    .card-footer
      .card-footer__category {{typeName}}
      router-link.card-footer__link(:to="{ name: type, params: { roomId: id}}") {{$t(`selection_card.router_btn`)}}
</template>

<script>
export default {
  name: "SelectionCard",
  props: {
    id: Number,
    type: String,
    stream: Object,
    speech: Object,
  },
  computed: {
    cardClass() {
      if(this.type === 'questions') {
        return 'selection-card_for-speaker'
      } else if (this.type === 'chat') {
        return 'selection-card_global'
      } else return ''
    },
    typeName() {
      return this.type === 'chat' ? this.$t(`selection_card.types.chat`) : this.$t(`selection_card.types.questions`)
    }
  }
}
</script>

<style scoped lang="scss">
  .selection-card {
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    border: 1px solid #e5e5e5;
    padding: 20px 24px;

    &_for-speaker {
      background-color: #a672ff;
      border-color: #a672ff;
      color: #fff;
      .card-footer {
        &__category {
          background-color: #b487ff;
        }
        &__link {
          color: #fff;
        }
      }
    }
    &_global {
      border-color: #f2f2f2;
      color: #222;
      .card-footer {
        &__category {
          background-color: #f2f2f2;
        }
        &__link {
          color: #a672ff;
        }
      }
    }
  }

  .title {
    max-width: 256px;
    min-height: 36px;
    font-size: 16px;
    font-weight: 600;
  }

  .card-footer {
    height: min-content;
    padding-top: 38px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &__category {
      font-size: 12px;
      font-weight: 600;
      padding: 4px 12px;
      border-radius: 35px;
    }
    &__link {
      font-size: 16px;
      font-weight: 600;
      text-decoration: none;
    }
  }
</style>