<template lang='pug'>
  .tooltip__wrap
    .tooltip(
      :style='{"width": width}'
      :class='classList'
    ) {{tooltip}}
</template>

<script>
export default {
  name: 'Tooltip',
  props: {
    tooltip: String,
    width: String,
    position: {
      type: String,
      default: 'top'
    }
  },
  computed: {
    classList () {
      return {
        [`tooltip_${this.position}`]: true,
      }
    },
  }
}
</script>

<style lang='scss' scoped>
.tooltip {
  width: auto;
  background: #222;
  color: #fff;
  border-radius: 3px;
  padding: 5px 10px;
  position: absolute;
  display: none;
  &__wrap {
    position: relative;
  }
  &:after {
    content: "";
    position: absolute;
    border: 7px solid transparent;
  }
  &__wrap {
    &:hover .tooltip {
      display: block;
    }
  }
  &_top {
    left: 50%;
    transform: translateX(-50%);
    margin-bottom: 13px;
    bottom: 100%;
    &:after {
      bottom: -13px;
      border-top-color: #222;
      transform: translateX(50%);
    }
    &-left {
      right: 100%;
      bottom: 100%;
      margin-bottom: 13px;
      &:after {
        right: 0;
        border-right-color: #222;
        bottom: -7px;
      }
    }
    &-right {
      left: 100%;
      bottom: 100%;
      margin-bottom: 13px;
      &:after {
        left: 0;
        border-left-color: #222;
        bottom: -7px;
      }
    }
  }

  &_right {
    left: 100%;
    top: 50%;
    transform: translateY(-50%);
    margin:0 0 0 13px;
    &:after {
      left: -14px;
      border-right-color:#222;
      bottom: 50%;
      transform: translateY(50%);
    }
  }
  &_bottom {
    left: 50%;
    transform: translateX(-50%);
    margin-top: 13px;
    top: 100%;
    &:after {
      left: 50%;
      border-bottom-color: #222;
      bottom: 100%;
      transform: translateX(-50%);
    }
    &-right {
      left: 100%;
      top: 100%;
      margin-top: 13px;
      &:after {
        left: 0;
        border-left-color: #222;
        bottom: 100%;
        transform: translateY(50%);
      }
    }
    &-left {
      right: 100%;
      top: 100%;
      margin-top: 13px;
      &:after {
        right: 0;
        border-right-color: #222;
        bottom: 100%;
        transform: translateY(50%);
      }
    }
  }
  &_left {
    right: 100%;
    top: 50%;
    transform: translateY(-50%);
    margin-right: 13px;
    &:after {
      right: -14px;
      border-left-color: #222;
      bottom: 50%;
      transform: translateY(50%);
    }
  }
}
</style>
