<template lang="pug">
  .form
    ZeenTitle.title(
      size="1"
      uiStyle="")
      span {{$t(`modals.write_message.title`)}}
    .message-input
      .message-input__description {{$t(`modals.write_message.description`)}}
      ZeenTextInput(
        type="textarea"
        v-model='message'
        :placeholder="$t(`modals.write_message.placeholder`)")
      InputFile(
        :text="$t(`modals.write_message.input_file_text`)"
        :label="$t(`modals.write_message.input_file_label`)"
        :textForBtn="$t(`modals.write_message.input_file_btn`)"
        :image="file"
        @change-input="fileExists"
        @remove='removePicture'
      )
    .buttons
      ZeenButton.buttons__button.buttons__button-outline(
        theme="outline"
        @click.prevent="closeModal") {{$t(`modals.write_message.cancel_btn`)}}
      ZeenButton.buttons__button(@click.prevent='sendMessage') {{$t(`modals.write_message.send_btn`)}}
</template>

<script>
import InputFile from '../../ui/InputFile';
export default {
  name: 'WriteMessageForm',
  components: {InputFile},
  data() {
    return {
      file: null,
      message: ''
    }
  },
  props: {
    isOpen: Boolean
  },
  methods: {
    fileExists(file) {
      this.file = file
    },
    removePicture() {
      this.file = null
    },
    closeModal() {
      this.$vfm.hide('write-message-modal')
    },
    sendMessage() {
      let formData = new FormData()
      formData.append('chat_room_id', this.$route.params.roomId)
      formData.append('chat_item[text]', this.message)
      formData.append('chat_item[picture]', this.file)


      this.$store.dispatch('chats/sendMessage', {data: formData, chat_room_id: this.$route.params.roomId})
      .then(() => {
        this.message = ''
        this.file = null

        this.$notify({
          type: 'success',
          text: this.$t(`notifications.successfully_sent`)
        })
        this.closeModal()
        this.$store.dispatch('chats/loadChats', this.$route.params.roomId)
      })
      .catch((data)=>{
        this.$notify({
          type: 'error',
          text: this.$t(`notifications.error`) + data
        })
      })
    }
  },
  watch: {
    isOpen() {
      this.message = ''
      this.file = null
    }
  }
}
</script>

<style lang="scss" scoped>
  .title {
    font-size: 24px;
    font-weight: 600;
  }

  .message-input {
    padding-top: 24px;

    &__description {
      font-size: 12px;
      color: #999;
      margin-bottom: 6px;
    }
  }
  .file-input-wrapper {
    padding-top: 17px;
  }
  .attached-image {
    padding-top: 24px;
    font-size: 12px;
    color: #999;

    .content-wrapper {
      display: flex;
      align-items: center;
      position: relative;

      &__img {
        position: relative;
        margin-top: 10px;
        width: 180px;
        height: 130px;
        border-radius: 10px;
      }

      &__btn {
        cursor: pointer;
        position: absolute;
        top: 20px;
        left: 145px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #fff;
        font-size: 8px;
        font-weight: 900;
        color: #999;
        padding: 6px;
        border-radius: 20px;
      }

      .replace-photo {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        &__input {
          position: absolute;
          visibility: hidden;
        }

        &__label {
          color: #a672ff;
          font-size: 16px;
          font-weight: 600;
          cursor: pointer;
        }
      }
    }
  }

  .buttons {
    display: flex;
    padding-top: 30px;

    &__button {
      flex-grow: 1;
    }

    &__button-outline {
      margin-right: 15px;
    }
  }
</style>