<template lang='pug'>
  .l-box(ref='col', :style='styles')
    .l-box__content
      ContentLoader(
        :width='width',
        :height='height',
        :speed='2',
        :style='styles',
        :primaryColor='primaryColor',
        :secondaryColor='secondaryColor'
      )
        rect(x='0', y='0', :rx='rx', :ry='ry', :width='width', :height='height')
</template>

<script>
import {ContentLoader} from 'vue-content-loader'

export default {
  name: 'LoadBox',
  props: {
    height: {
      type: Number,
      default: 20,
    },
    primaryColor: {
      type: String,
      default: '#e0e0e0',
    },
    secondaryColor: {
      type: String,
      default: '#e9e9e9',
    },
    rx: {
      type: Number,
      default: 3
    },
    ry: {
      type: Number,
      default: 3
    }
  },
  components: {
    ContentLoader,
  },
  data() {
    return {
      width: 100,
    }
  },
  mounted() {
    this.calcWidth()
    document.addEventListener('resize', this.calcWidth)
    document.addEventListener('table-resize', this.calcWidth)
  },
  destroyed() {
    document.removeEventListener('resize', this.calcWidth)
    document.removeEventListener('table-resize', this.calcWidth)
  },
  methods: {
    calcWidth() {
      this.width = this.$refs.col.clientWidth
    },
  },
  computed: {
    styles() {
      return {
        height: this.height + 'px',
      }
    },
  },
}
</script>

<style scoped lang='scss'>
.l-box {
  position: relative;
  z-index: 0;

  &__content {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    padding-right: 30px;
  }

  & ::v-deep svg {
    display: block;
  }
}
</style>