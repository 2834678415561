<template lang="pug">
  .side-panel
    .side-panel__content
      ZeenLogo(
        color='#A672FF'
        :width='116'
        :height='57')
      ZeenTitle.side-panel__title(
        size="1"
        uiStyle="title")
      //- ZeenSelect.side-panel__select.side-panel__select_first(
        :label="$t(`side_panel_rooms.day_label`)"
        @input="filterDayChange"
        :value='day'
        :placeholder="$t(`side_panel_rooms.day_select`)"
        :list='daysSelect')
      ZeenSelect.side-panel__select(
        :label="$t(`side_panel_rooms.stream_label`)"
        @input="filterStreamChange"
        :value='stream'
        :placeholder="$t(`side_panel_rooms.stream_select`)"
        :list="streamsSelect")
      ZeenSelect.side-panel__select(
        :label="$t(`side_panel_rooms.speach_label`)"
        @input="filterSpeechChange"
        :value='speech'
        :placeholder="$t(`side_panel_rooms.speach_select`)"
        :list='speechesSelect')
      ZeenButton(
        theme='string'
        @click.prevent='resetFilter') {{$t(`side_panel_rooms.reset`)}}
    .side-panel__lang
      SelectLang
</template>

<script>
import ZeenLogo from '../ui/ZeenLogo'
import SelectLang from '../ui/SelectLang'

export default {
  name: "SidePanel",
  components: {
    ZeenLogo,
    SelectLang
  },
  data() {
    return {
      day: null,
      stream: null,
      speech: null
    }
  },
  mounted() {
    // this.$store.dispatch('conference/loadFullConferenceData', this.conferenceId)
    // this.setCurrentDay()
    // this.filterDayChange(this.day.value)
  },
  methods: {
    loadStreams(dayId) {
      this.$store.dispatch('conference/loadStreams', dayId)
    },
    setCurrentDay() {
      if (this.$route.params.dayId) {
        this.day = this.daysSelect.find(item => {
          return item.value === this.$route.params.dayId
        })
      } else {
        this.day = this.daysSelect[0]
        this.$router.push(`/${this.day.value}`)
      }
    },
    resetFilter() {
      this.stream = null
      this.speech = null
    },
    filterDayChange(value) {
      this.day = value
      this.stream = null
      this.speech = null
      this.$emit('filter', {day: value})
    },
    filterStreamChange(value) {
      this.stream = value
      this.speech = null
      this.$emit('filter', {stream: value})
    },
    filterSpeechChange(value) {
      this.stream = null
      this.speech = value
      this.$emit('filter', {speech: value})
    },
  },
  computed: {
    daysSelect() {
      const days = this.$store.getters['chatrooms/getDays'] 
      return days.map(day => ({label: day.name, value: day.id}))
    },
    streamsSelect() {
      const streams = []
      this.$store.getters['chatrooms/getStreams'].forEach(item => {
        if (item.name && item.id) {
          streams.push(item)
        }
      })
      return streams.map(stream => ({label: stream.name, value: stream.id}))
    },
    speechesSelect() {
      const speeches = []
      this.$store.getters['chatrooms/getSpeeches'].forEach(item => {
        if (item.title && item.id) {
          speeches.push(item)
        }
      })
      return speeches.map(speech => ({label: speech.title, value: speech.id}))
    },
  },
  watch: {
    day() {
      this.$emit('filter', {day: this.day })
    },
    speech() {
      this.$emit('filter', {speech: this.speech })
    },
    stream() {
      this.$emit('filter', {stream: this.stream })
    },
  },
}

</script>

<style scoped lang="scss">
  .side-panel {
    position: fixed;
    left: 0;
    top: 0;
    max-width: 347px;
    width: 100%;
    height: 100%;
    background-color: #222;
    padding: 20px 30px 0 40px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .side-panel__select {
      padding-top: 12px;

      ::v-deep .z-input__wrapper {
        background-color: #343434;
      }

      ::v-deep .z-input__select {
        color: #fff;
      }

      ::v-deep .z-input__dropdown-item {
        color: #fff;

        &:hover {
          color: #222;
        }

        &_selected {
          color: #222;
        }
      }

      &_first {
        padding: 37px 0 25px;
      }
    }

    .ZeenTitle_title {
      color: #fff;
      font-size: 18px;
      padding: 21px 0 0;
    }

    &__lang {
      padding: 50px 0;
    }

  }

  .buttons {
    display: flex;
    justify-content: space-between;
    &__exit {
      .icon-upload {
        transform: rotate(90deg);
        margin-right: 10px;
      }
    }

    .icon-chevron-down {
      transform: rotate(90deg);
    }
  }
</style>