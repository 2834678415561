<template lang="pug">
  .side-panel
    .side-panel__content
      ZeenLogo(
        color='#A672FF'
        :width='116'
        :height='57'
      )
      ZeenTitle.side-panel__title(
        size="1"
        uiStyle="title")
        span(v-if="speechName") {{speechName}}  /
        span  {{streamName}}

      .input-search
        .input-search__label {{$t(`side_panel_chat.search_label`)}}
        input.input-search__input(v-model="filterText" :placeholder='$t(`side_panel_chat.search_placeholder`)')
        .icon-magnifier.input-search__icon
      SelectFilter(
        class="select-wrapper"
        :active='activeFilter',
        :list='listFilter',
        :nameFilter='nameFilter',
        @input='changeFilter'
      )
      ZeenButton.side-panel__btn-file(@click.prevent='exportChats')
        .icon-file
        span {{$t(`side_panel_chat.buttons.export_excel`)}}
    .side-panel__lang
      SelectLang
</template>

<script>
import ZeenLogo from '../ui/ZeenLogo'
import SelectFilter from '../ui/SelectFilter'
import SelectLang from '../ui/SelectLang'

export default {
  name: "SidePanelChat",
  components: {
    ZeenLogo,
    SelectFilter,
    SelectLang
  },
  props: {
    streamName: String,
    speechName: String
  },
  data() {
    return {
      filterText: "",
      activeFilter: {value: 'all', label: this.$t(`side_panel_chat.filter_list.all`)},
      listFilter: [
        {value: 'all', label: this.$t(`side_panel_chat.filter_list.all`)},
        {value: 'approved', label: this.$t(`side_panel_chat.filter_list.approved`)},
        {value: 'rejected', label: this.$t(`side_panel_chat.filter_list.rejected`)},
      ],
      nameFilter: this.$t(`side_panel_chat.filter_list.title`)
    }
  }, 
  methods: {
    changeFilter(filter) {
      this.activeFilter = filter
      this.$emit('filter', this.activeFilter)
    },
    exportChats() {
      this.$api.project['admin.export'](this.$route.params.roomId).catch(
        (response) => {
          const blobFile = new Blob([response.data])
          const url = window.URL.createObjectURL(blobFile)
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', this.$route.params.roomId + '-chats.xls')
          document.body.appendChild(link)
          link.click()
          document.body.removeChild(link)
        },
      )
    },
  },
  watch: {
    filterText() {
      this.$emit('search', this.filterText)
    }
  }
}
</script>

<style scoped lang="scss">
  .side-panel {
    position: fixed;
    left: 0;
    top: 0;
    max-width: 347px;
    width: 100%;
    height: 100%;
    background-color: #222;
    padding: 20px 30px 0 40px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &__title.title {
      font-weight: 600;
      font-size: 18px;
      line-height: 22px;
      color: #FFFFFF;
      margin-top: 60px;
    }
    &__btn-file .icon-file{
      margin-right: 5px;
    }

    &__lang {
      padding: 50px 0;
    }

  }

  .input-search {
    display: flex;
    flex-direction: column;
    margin-top: 40px;
    position: relative;

    &__label {
      color: #999;
      font-size: 12px;
      margin-bottom: 6px;
      bottom: 110px;
    }

    &__input {
      padding: 20px 20px 20px 46px;
      background-color: #343434;
      border-radius: 10px;
      border: none;
      outline: none;
      color: #fff;
      font-size: 16px;
    }

    &__icon {
      position: absolute;
      top: 40px;
      left: 20px;
      width: 20px;
      color: #999;
      cursor: pointer;
    }
  }
  .select-wrapper {
    margin-top: 10px;
    margin-bottom: 30px;
  }
</style>