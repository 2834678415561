import zeenApi from '../../api/api'
import moment from 'moment'

const conference = {
  namespaced: true,

  state: {
    conference: {},
    days: {},
    streams: {},
    speeches: {},
  },

  mutations: {
    saveDays(state, {conference_id, days}) {
      let daysOld = state.days

      state.days = {
        ...daysOld,
        [conference_id]: days.sort((dayA, dayB) => {
          return (
            moment(dayA.date, 'YYYY-MM-DD') - moment(dayB.date, 'YYYY-MM-DD')
          )
        }),
      }
    },
    saveStreams(state, {conference_day_id, streams}) {
      let oldStreams = state.streams

      streams = streams.sort((streamsA, streamsB) => streamsA.created_at - streamsB.created_at)

      state.streams = {
        ...oldStreams,
        [conference_day_id]: streams,
      }
    },
    saveSpeeches(state, {stream_id, speeches}) {
      let oldSpeeches = state.speeches

      state.speeches = {
        ...oldSpeeches,
        [stream_id]: speeches.sort((speechA, speechB) => {
          return (
            moment(speechA.time_begin, 'hh:mm') -
            moment(speechB.time_begin, 'hh:mm')
          )
        }),
      }
    },
    saveCurrentTimes(state, {stream_code, currentTime}) {
      state.currentTimes = {
        ...state.currentTimes,
        [stream_code]: currentTime,
      }
    },
    saveConference(state, conference) {
      state.conference = conference
    }
  },

  actions: {
    loadFullConferenceData({commit, dispatch}, conference_code) {
      return Promise.all([
        zeenApi.project['admin.conference.load'](conference_code)
          .then(({conference = {}}) => {
            commit('saveConference', conference)
            const {conference_days = []} = conference
            commit('saveDays', {
              conference_id: conference_code,
              days: conference_days,
            })

            return conference_days
          })
          .then(conference_days => {
            const promices = []

            conference_days.forEach(day => {
              promices.push(dispatch('loadStreams', day.id))
            })

            return Promise.all(promices)
          })
          .then(streamsData => {
            //перебираем стримы дней превращаем в полскую колекцию
            return streamsData
              .map(sd => sd.streams)
              .flat()
              .map(stream => stream.id)
          })
          .then(streamIds => {
            const promices = []
            //начинаем загрузку спичей стрима
            streamIds.forEach(streamId => {
              promices.push(dispatch('loadSpeeches', streamId))
            })

            return Promise.all(promices)
          }),
      ])
    },
    loadConference({ commit }, conference_code) {
      return zeenApi.project['admin.conference.load'](conference_code).then((data) => {
        commit('saveConference', data.conference)
      })
    },
    loadDays({commit }, conference_id) {

      return zeenApi.project['admin.conferences.days']({
        conference_id,
      }).then((data) => {
        commit('saveDays', {
          conference_id,
          days: data.conference_days,
        })

        return data
      })
    },
    loadStreams({commit }, conference_day_id) {
      return zeenApi.project['admin.conference.streams']({
        conference_day_id,
      }).then((data) => {
        commit('saveStreams', {
          conference_day_id,
          streams: data.streams,
        })

        return data
      })
    },

    loadSpeeches({commit}, stream_id) {
      return zeenApi.project['admin.conference.speeches'](stream_id).then(
        (data) => {
          commit('saveSpeeches', {
            stream_id,
            speeches: data.speeches,
          })

          return data
        },
      )
    },

  },
  getters: {
    getConference: (state) => state.conference,
    getDays: (state) => {
      const days = []
      for (let key in state.days) {
        days.push(...state.days[key])
      }

      return days
    },
    getDayById: (state, getters) => (day_id) =>
      getters.getDays.find((day) => day.id === day_id) || {},
    getDaysByConferenceId: (state) => (conference_id) => {
      return state.days[conference_id] || []
    },
    getDayByStreamId: (state, getters) => (stream_id) => {
      const days = getters.getDays
      const day = days.find((daysData) => daysData.stream_ids.find(day_stream_id => day_stream_id === stream_id))
      return day
    },
    // WORK WITH STREAMS
    getStreams: (state) => {
      const streams = []
      for (let key in state.streams) {
        streams.push(...state.streams[key])
      }

      return streams
    },
    getStreamsByDayId: (state) => (conference_day_id) =>
      state.streams[conference_day_id] || [],
    getStreamById: (state, getters) => (stream_id) =>
      getters.getStreams.find((stream) => stream.id === stream_id) || {},
    getStreamsByConferenceId: (state, getters) => (conference_id) => {
      const days = getters.getDaysByConferenceId(conference_id)

      return days.map(day => {
        return getters.getStreamsByDayId(day.id)
      }).flat()
    },
    getStreamBySpeechId: (state, getters) => (speech_id) => {
      const speeches = state.speeches
      const speechesArray = Object.entries(speeches)

      const [streamId] = speechesArray.find(([speechesInStream]) => {
        const idSpeech = speechesInStream.find(speech => speech.id === speech_id)
        return !!idSpeech
      })

      return getters.getStreamById(streamId)
    },

    // WORK WITH Speeches
    getSpeechesByStreamId: (state) => (stream_id) => {
      return state.speeches[stream_id] || []
    },
    getSpeechesByDayId: (state, getters) => (day_id) => {
      const streams = getters.getStreamsByDayId(day_id)

      const streamsSpeeches = streams.map(stream => {
        return getters.getSpeechesByStreamId(stream.id)
      })

      return streamsSpeeches.flat()
    },
    getSpeechesByConferenceId: (state, getters) => (conference_id) => {
      const days = getters.getDaysByConferenceId(conference_id)

      const daysSpeeches = days.map(day => {
        return getters.getSpeechesByDayId(day.id)
      })

      return daysSpeeches.flat()
    },
    getSpeechById: (state) => (speech_id) => {
      let speechFound = null

      for (let key in state.speeches) {
        let arraySpeeches = state.speeches[key]

        if (Array.isArray(arraySpeeches)) {
          arraySpeeches.forEach((speech) => {
            if (speech.id === speech_id) {
              speechFound = speech
            }
          })
        }
      }

      return speechFound
    },
    getCurrentTimeByStreamId: (state) => (stream_id) => {
      return state.currentTimes[stream_id] || 0
    },

    // WORK WITH Speakers
    getSpeakerById: (state) => (speaker_id) => {
      const speakers = Object.values(state.speakers).flat()

      return speakers.find(function(speaker) {
        return speaker.id === speaker_id
      })
    },
  },
}

export default conference
