import Vue from 'vue';
import Router from 'vue-router';
import store from '../store/index'
import RoomSelect from '../views/RoomSelect';
import Chat from '../views/Chat'
import Questions from '../views/Questions';
import Auth from '../views/Authorization/Auth';

Vue.use(Router);

const router = new Router({
  routes: [
    {
      path: '/',
      name: 'room-select', 
      component: RoomSelect,
      meta: {
        title() {
          return 'Room Select'
        },
        requiresAuth: true,
      },
    },
    {
      path: '/:dayId',
      name: 'room-select-day', 
      component: RoomSelect,
      meta: {
        title() {
          return 'Room Select'
        },
        requiresAuth: true,
      },
    },
    {
      path: '/chat/:roomId', 
      name: 'chat', 
      component: Chat,
      meta: {
        title() {
          return 'Chat'
        },
        requiresAuth: true,
      },
    },
    {
      path: '/questions/:roomId', 
      name: 'questions', 
      component: Questions,
      meta: {
        title() {
          return 'Questions'
        },
        requiresAuth: true,
      },
    },
    {path: '/auth', name: 'auth', component: Auth},
  ],
  mode: 'history',
})
router.beforeEach((to, from, next) => {  
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (store.getters.isAuth) {
      next()
      return
    }

    next({name: 'auth'})
  } else {
    next()
  }
})
export default router;