import zeenApi from '../../api/api'

const chats = {
  namespaced: true,

  state: {
    chats: [],
  },

  mutations: {
    saveChats(state, chats) {
      state.chats = chats
    },
    updateChatItem(state, data) {
      state.chats.splice(data.index,1, data.chat_item) 
    },
    addChatItem(state, chat_item) {
      if (!state.chats.some(item => item.id === chat_item.id)) {
        state.chats.unshift(chat_item)
      }
    },
  },

  actions: {
    loadChats({ commit }, roomId) {
      return zeenApi.project['admin.chats.load'](roomId).then((data) => {
        commit('saveChats', data.chat)
      })
    },
    sendMessage({ commit, state }, params) {
      return zeenApi.project['admin.chats.send'](params.data, params.chat_room_id).then(() => {
        commit('saveChats', state.chats)
      })
    },
    replyMessage({ commit, state }, params) {
      return zeenApi.project['admin.chats.reply'](params.data, params.id).then(() => {
        commit('saveChats', state.chats)
      })
    },
  },
  getters: {
    getChats: (state) => state.chats,
  }
}

export default chats
