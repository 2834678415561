<template lang="pug">
  ZeenModal(
    contentClass='modal-content',
    name='write-message-modal',
    v-model='show'
  )
    template(v-slot='{params}')
      WriteMessageForm(
        :isOpen='show'
      )
</template>

<script>
import ZeenModal from '../ui/ZeenModal';
import WriteMessageForm from './forms/WriteMessageForm';

export default {
  name: 'WriteMessageModal',
  components: {
    ZeenModal,
    WriteMessageForm
  },
  data() {
    return {
      show: false
    }
  }
}
</script>