<template lang="pug">
  .message(:class="messageClass")
    .message-header
      .main-info(:class="mainInfoClass")
        .main-info__avatar(v-if="!loading")
          .icon-profile
          .icon-block(v-if="iconBlockVisible")
        .main-info__avatar_loading(v-if="loading")
          LoadBox(:height="20" :rx="20" :ry="20")
        .main-info__user-name(v-if="!loading") {{message.userName}}
        .main-info__user-name_loading(v-else)
          LoadBox(:height="22")
        .main-info__time(v-if="!loading") {{time}}
        .main-info__time_loading(v-else)
          LoadBox(:height="22")
        .main-info__user-id(v-if="!loading") ID  {{message.id}}
        .main-info__user-id_loading(v-else)
          LoadBox(:height="22")
        .main-info__addressed-to(v-if="!loading") {{message.addressed_to}}
        .main-info__addressed-to_loading(v-else)
          LoadBox(:height="22")
      .message-header__status(v-if="!loading")
        p {{message.status}}
        .icon.icon-check-mark(v-if="message.status === 'approved'")
        .icon.icon-block(v-else)
      .message-header__status_loading(v-else)
        LoadBox(:height="22")
    .message-body
      .secondary-info(v-if="!loading")
        .secondary-info__title(
          :class="speechName ? 'secondary-info__title_framed' : ''")
          span.secondary-info__stream-name {{streamName}}
          Tooltip.stream-tooltip(
            :tooltip="streamName"
            position="top-right"
            width="max-content")
        .secondary-info__title
          span.secondary-info__speech-name {{speechName}}
          Tooltip.speech-tooltip(
            :tooltip="speechName"
            position="top-right"
            width="max-content")
      .to-answer(v-if="message.is_reply && !loading")
        .to-answer__author {{message.userReply}}
        .to-answer__content {{message.text}}
      .message-body__content(v-if="!loading") {{messageText}}
        img.message-body__picture( v-if="messagePicture" :src="messagePicture" @click.prevent="$emit('open-picture', messagePicture)")
      .message-body__content_loading(v-else)
        LoadBox(:height="22")
      .message-body__buttons(v-if="!message.isAdmin && !loading")
        ZeenButton.button.button-reply(
          theme="string"
          @click.prevent="showReplyToMessageModal"
          :disabled="message.status === 'rejected'")
          .icon-reply
          span {{$t(`message.buttons.answer`)}}
        ZeenButton.button(
          theme="string"
          v-if="message.status === 'approved'"
          @click.prevent='rejectChat'
          )
          .icon-block
          span {{$t(`message.buttons.reject`)}}
        ZeenButton.button.button-to-publish(
          @click.prevent='approveChat'
          theme="string"
          :disabled="message.userBan"
          :class="message.userBan ? 'button-to-publish_disabled' : ''"
          v-if="message.status === 'rejected'")
          .icon-check-mark
          span {{$t(`message.buttons.post`)}}
        Tooltip.tooltip-to-publish(
          v-if="message.userBan"
          position="top-right"
          :tooltip="$t(`ui.tooltips.unban_autor`)"
          width="max-content")
        ZeenButton.button(
          @click.prevent='userBan'
          theme="string"
          v-if="!message.userBan")
          .icon-lock
          span {{$t(`message.buttons.ban`)}}
        ZeenButton.button(
          @click.prevent='userUnban'
          theme="string"
          v-if="message.userBan")
          .icon-unlock
          span {{$t(`message.buttons.unban`)}}
      .message-body__buttons(v-if="!loading")
        ZeenButton.button(
          v-if="message.isAdmin && message.status === 'approved'"
          theme="string"
          @click.prevent='rejectChat'
        )
          .icon-basket
          span {{$t(`message.buttons.delete`)}}
      .message-body__buttons_loading(v-if="loading")
        .button-first
          LoadBox(:height="35")
        .button-second
          LoadBox(:height="35")
        .button-third
          LoadBox(:height="35")
</template>

<script>
import LoadBox from '../ui/LoadBox';
import Tooltip from '../ui/Tooltip';
import moment from 'moment'

export default {
  name: "Message",
  components: {LoadBox, Tooltip},
  props: {
    message: Object,
    messages: Array,
    speechName: String,
    streamName: String
  },
  data() {
    return {
      loading: false
    }
  },
  methods: {
    showReplyToMessageModal() {
      this.$emit('show-reply-to-message-modal', {data: this.message})
    },
    rejectChat() {
      this.$api.project['admin.chats.reject'](this.message.id)
        .then(()=> {
          this.message.status = 'rejected'
          this.$notify({
            type: 'success',
            text: this.$t(`notifications.message_rejected`)
          })
        })
        .catch((data)=>{
        this.$notify({
          type: 'error',
          text: this.$t(`notifications.error`) + data
        })
      })
    },
    approveChat() {
      this.$api.project['admin.chats.approve'](this.message.id)
        .then(()=> {
          this.message.status = 'approved'
          this.$notify({
            type: 'success',
            text: this.$t(`notifications.message_approved`)
          })
        })
        .catch((data)=>{
        this.$notify({
          type: 'error',
          text: this.$t(`notifications.error`) + data
        })
      })
    },
    userBan() {
      this.$api.project['admin.user.ban'](this.message.id)
        .then(()=> {
          // this.messages.forEach(item => {
          //   if (item.userId === this.message.userId) {
          //     this.$api.project['admin.chats.reject'](item.id)
          //     .then(() => item.status = 'rejected')
          //   }
          //   // this.$store.dispatch('chats/loadChats', this.$route.params.roomId)
          // })
          this.$store.getters['chats/getChats'].forEach((item, index) => {
            if (item.userId === this.message.userId) {
              // Создайте новый объект или модифицируйте существующий, чтобы избежать прямых изменений
              let updatedItem = {...item, userBan: true, user: {...item.user, ban: true}};
              this.$store.commit('chats/updateChatItem', {chat_item: updatedItem, index});
            }
          });

          this.$notify({
            type: 'success',
            text: this.$t(`notifications.user_was_banned`)
          })
        })
        .catch((data)=>{
        this.$notify({
          type: 'error',
          text: this.$t(`notifications.error`) + data
        })
      })
    },
    userUnban() {
      this.$api.project['admin.user.unban'](this.message.id)
          .then(()=> {
            this.$store.getters['chats/getChats'].forEach((item, index) => {
              if (item.userId === this.message.userId) {
                // Создайте новый объект или модифицируйте существующий, чтобы избежать прямых изменений
                let updatedItem = {...item, userBan: false, user: {...item.user, ban: false}};
                this.$store.commit('chats/updateChatItem', {chat_item: updatedItem, index});
              }
            });
            // this.$store.dispatch('chats/loadChats', this.$route.params.roomId)
            this.$notify({
              type: 'success',
              text: this.$t(`notifications.user_was_unbanned`)
            })
          })
          .catch((data)=>{
            this.$notify({
              type: 'error',
              text: this.$t(`notifications.error`) + data
            })
          })
    }
  },
  computed: {
    messageClass() {
      if (this.message.status === 'rejected' && !this.message.userBan) {
        return 'message_blocked'
      }
      if (this.message.userBan) {
        return 'message_user-blocked'
      }
      return ''
    },
    mainInfoClass() {
      if(this.message.isAdmin) {
        return 'main-info_admin'
      }
      return ''
    },
    iconBlockVisible() {
      return !!(this.message.userBan && !this.message.isAdmin)
    },
    messageText() {
      return this.message.is_reply ? this.message.reply_text : this.message.text
    },
    messagePicture() {
      return this.message?.picture?.url ?? ''
    },
    time() {
      return moment(this.message.created_at).format('HH:mm')
    },
    chatRoomId() {
      return this.$store.getters['chatrooms/getChatroomById'](this.$route.params.roomId).id
    },
  }
}
</script>

<style scoped lang="scss">
.message {
  border-bottom: 1px solid #e5e5e5;
  padding: 20px 0 30px;

  &_blocked {
    .message-header {
      &__status {
        color: #ff4d4d;
      }
    }
    .main-info__user-name {
      color: #999;
    }

    .message-body {
      color: #999;

      &__buttons {
        .button-reply{
          color: #999;

          .icon-reply {
            color: #999;
          }
        }
      }
    }
  }

  &_user-blocked {
    .message-header {
      &__status {
        color: #ff4d4d;
      }
    }
    .main-info__avatar {
      position: relative;
      background-color: #222 !important;

      .icon-block {
        position: absolute;
        color: #ff4d4d;
        font-size: 14px;
      }
    }
    .main-info__user-name {
      color: #ff4d4d;
    }

    .message-body {
      color: #999;
      &__buttons {
        position: relative;
        .button-reply {
          color: #999;

          .icon-reply {
            color: #999;
          }
        }
        .button-to-publish {
          color: #999;

          &:hover + .tooltip-to-publish {
            ::v-deep .tooltip {
              display: flex;
              font-size: 12px;
              position: absolute;
              left: -110px;
            }
          }
          .icon-check-mark {
            color: #999;
          }
        }
      }

    }
  }
}

.message-header {
  display: flex;
  justify-content: space-between;
  align-items: center;

  &__status {
    display: flex;
    align-items: center;
    color: #00943e;

    &_loading {
      width: 122px;
    }

    .icon {
      margin-left: 8px;
      font-size: 14px;
    }
  }

  .main-info {
    display: flex;
    align-items: center;
    &__avatar {
      background-color: #999;
      width: 20px;
      height: 20px;
      border-radius: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
      font-size: 13px;

      &_loading {
        width: 20px;
        margin-right: 10px;
      }
    }

    &__user-name {
      font-size: 12px;
      font-weight: 600;
      padding-left: 10px;

      &_loading {
        width: 107px;
        margin-right: 14px;
      }
    }

    &__time {
      font-size: 12px;
      color: #999;
      padding-left: 15px;

      &_loading {
        width: 33px;
        margin-right: 14px;
      }
    }

    &__user-id {
      font-size: 12px;
      margin-left: 15px;
      padding: 3px 6px;
      border: 1px solid #e5e5e5;
      border-radius: 5px;

      &_loading {
        width: 56px;
        margin-right: 14px;
      }
    }

    &__addressed-to {
      font-size: 12px;
      font-weight: 600;
      color: #a672ff;
      background-color: #f8f4ff;
      border-radius: 35px;
      margin-left: 15px;
      padding: 4px 12px;

      &_loading {
        width: 88px;
      }
    }

    &_admin {
      .main-info__avatar {
        background-color: #a672ff !important;
      }

      .main-info__user-name {
        color: #a672ff !important;
      }
    }
  }
}

.message-body {
  margin-left: 30px;

  &__content {
    display: flex;
    flex-direction: column;
    font-size: 16px;
    padding-top: 10px;

    &_loading {
      width: 790px;
      margin-top: 19px;
    }
  }

  &__picture {
    max-width: 150px;
    margin-top: 10px;
    cursor: pointer;
  }

  &__buttons {
    display: flex;
    padding-top: 20px;

    &_loading {
      display: flex;
      margin-top: 20px;
      .button-first {
        width: 101px;
        margin-right: 10px;
      }
      .button-second {
        width: 208px;
        margin-right: 10px;
      }
      .button-third {
        width: 183px;
      }
    }

    ::v-deep {
      .zeen-button {
        color: #222;
        font-size: 12px;
        margin-right: 10px;
        border: 1px solid #e5e5e5;
        padding: 10px 12px;
        height: min-content;

        span {
          margin-left: 12px;
        }

        .icon-reply {
          color: #a672ff;
        }
        .icon-block {
          color: #ff4d4d;
        }
        .icon-lock {
          color: #ff4d4d;
        }
        .icon-unlock {
          color: #00943e;
        }
        .icon-basket {
          color: #a672ff;
          font-size: 14px;
          font-weight: 600;
        }
        .icon-check-mark {
          color: #00943e;
        }

        &_disabled {
          color: #999;

          :hover {
            color: #999;
          }

          .icon-check-mark {
            color: #999;
          }
        }
      }
    }
  }

  .to-answer {
    padding-left: 10px;
    border-left: 2px solid #a672ff;
    margin: 10px 0;
    font-size: 12px;

    &__author {
      font-weight: 600;
    }
  }
}

.secondary-info {
  font-size: 12px;
  position: relative;
  display: flex;

  &__title {
    color: #999;
    width: min-content;

    span {
      width: min-content;
    }

    &_framed {
      position: relative;
      color: #999;
      padding-right: 12px;
      margin-right: 12px;
      border-right: 1px solid #e5e5e5;
    }
  }
  &__stream-name {
    width: 150px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    display: inline-block;
    vertical-align: middle;
    &:hover {
      cursor: default;
      ~ .stream-tooltip {
        ::v-deep .tooltip {
          display: flex;
          position: absolute;
          left: 0;
          bottom: 10px;
        }
      }
    }
  }

  &__speech-name {
    &:hover {
      cursor: default;
      ~ .speech-tooltip {
        ::v-deep .tooltip {
          display: flex;
          position: absolute;
          left: 0;
          bottom: 10px;
        }
      }
    }
  }
}
</style>