import zeenApi from '../../api/api'

const questions = {
  namespaced: true,

  state: {
    questions: [],
  },

  mutations: {
    saveQuestions(state, questions) {
      state.questions = questions
    },
    updateQuestion(state, data) {
      state.questions.splice(data.index,1, data.question) 
    },
    addQuestion(state, question) {
      if (!state.questions.some(item => item.id === question.id)) {
        state.questions.unshift(question)
      }
    },
    deleteQuestion(state, index) {
      state.questions.splice(index,1) 
    },
  },

  actions: {
    loadQuestions({ commit }, roomId) {
      return zeenApi.project['admin.questions.load'](roomId).then((data) => {
        commit('saveQuestions', data.questions)
      })
    },
    replyQuestion({ commit, state }, params) {
      return zeenApi.project['admin.questions.reply'](params).then(() => {
        commit('saveQuestions', state.questions)
      })
    },
  },
  getters: {
    getQuestions: (state) => state.questions,
  }
}

export default questions
