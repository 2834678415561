<template lang='pug'>
.select-filter
  .select-filter__sort
    .select-filter__name {{nameFilter}}
    .select-filter__drop
      .select-filter-drop
        .select-filter-drop__header
          .select-filter-drop__value {{active.label}}
          .select-filter-drop__action
            .icon-chevron-down
        .select-filter-drop__body
          ul.select-filter-drop__list
            li.select-filter-drop__item(v-for='item in list')
              a.select-filter-drop__link(
                href='#',
                @click.prevent='$emit("input", item)',
                :class='{"select-filter-drop__link_active": item === active}'
              ) {{item.label}}
</template>

<script>
export default {
  name: 'SelectFilter',
  data() {
    return {}
  },
  props: {
    nameFilter: String,
    active: Object,
    list: Array,
  },
}
</script>

<style scoped lang='scss'>
.select-filter {
  padding: 15px 0;
  display: flex;
  align-items: center;

  &__sort {
    padding: 5px 0;
    display: flex;
  }

  &__name {
    font-size: 16px;
    line-height: 16px;
    color: #999999;
  }

  &__drop {
    padding-left: 8px;
  }

  &__form {
    margin: 0 0 0 auto;
  }
}

.select-filter-drop {
  position: relative;

  &:hover {
    .select-filter-drop__body {
      opacity: 1;
      visibility: visible;
    }
  }

  &__header {
    display: flex;
    color: #a672ff;
    font-size: 16px;
    line-height: 16px;
  }

  &__value {
    font-weight: 600;
  }

  &__action {
    margin-left: 4px;

    .icon-chevron-down {
      display: inline-block;
      vertical-align: middle;
      font-size: 10px;
    }
  }

  &__body {
    opacity: 0;
    visibility: hidden;
    margin-top: 5px;
    position: absolute;
    background: #ffffff;
    box-shadow: 0px 4px 15px rgba(170, 170, 170, 0.25);
    border-radius: 5px;
    top: 100%;
    left: 50%;
    transform: translate(-50%, 0);
    padding: 0 12px 10px;
    min-width: 100px;
    z-index: 100;
    transition: all 0.3s;

    &::before {
      content: '';
      display: block;
      position: absolute;
      z-index: 1;
      width: 120%;
      height: 5px;
      bottom: 100%;
      left: 50%;
      transform: translate(-50%, 0);
    }
  }

  &__list {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  &__item {
    padding-top: 8px;
    line-height: 16px;
    font-size: 12px;
  }

  &__link {
    font-style: normal;
    font-weight: normal;

    &,
    &:focus,
    &:visited {
      color: #222222;
      text-decoration: none;
    }

    &:hover,
    &:active {
      color: #a672ff;
      text-decoration: none;
    }

    &_active {
      font-weight: 600;

      &,
      &:focus,
      &:visited,
      &:hover,
      &:active {
        color: #a672ff;
        text-decoration: none;
      }
    }
  }
}

</style>
