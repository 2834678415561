<template lang="pug">
  .questions
    SidePanelQuestions(
      @filter='filter'
      @search='search'
      @sorting='sorting'
      @speackerFilter='speackerFilter'
      :speechName='speechName'
      :streamName='streamName'
      :speakers='speakers'
    )
    .main-content
      .header
        .buttons
          ZeenButton(
            theme="string"
            @click.prevent="toRooms").buttons__exit
            .icon-arrow
            p {{$t(`questions.back_btn`)}}
        .buttons
          ZeenButton(
            theme="string"
            @click.prevent="logout").buttons__exit
            .icon-upload
            p {{$t(`questions.logout_btn`)}}
      QuestionsList(
        :questions='sortingQuestions'
        @show-replay-to-question-modal="showReplyToQuestionModal"
      )
    ReplyToQuestionModal
</template>

<script>
import SidePanelQuestions from '../components/layout/SidePanelQuestions';
import QuestionsList from '../components/questions/QuestionsList';
import ReplyToQuestionModal from '../components/modals/ReplyToQuestionModal';
import { searchInArrayFields } from '../helpers/searchable';
import CentrifugeSocket from '../helpers/CentrifugeSocket';

export default {
  name: "Questions",
  components: { ReplyToQuestionModal, QuestionsList, SidePanelQuestions },
  data() {
    return {
      filterValue: null,
      filterText: "",
      filterSpeacker: null,
      sortingValue: null,
      typeValue: null,
      roomId: null,
    };
  },
  async mounted() {
    await this.$store.dispatch('questions/loadQuestions', this.$route.params.roomId);
    await this.initCentrifuge();
    this.centrifugeChannelSubscribe();
    this.roomId = this.$route.params.roomId;
  },
  destroyed() {
    this.offCentrifuge();
  },
  methods: {

    initCentrifuge() {
      this.$api.project['admin.authorizations']()
        .then((data) => {
          CentrifugeSocket.connect({
            centrifugeToken: data.data.token,
          });
        })
        .catch((data) => {
          CentrifugeSocket.connect({
            centrifugeToken: data.data.token,
          });
        }); 
    },

    centrifugeChannelSubscribe() {
      CentrifugeSocket.subscribe(`admin-comments-chat-room-${this.$route.params.roomId}`);
      CentrifugeSocket.on('question_create', this.loadNewQuestions);
      CentrifugeSocket.on('question_update', this.updateQuestions);
    },
    offCentrifuge() {
      CentrifugeSocket.unsubscribe(`admin-comments-chat-room-${this.roomId}`);
      CentrifugeSocket.off('question_create', this.loadNewQuestions);
      CentrifugeSocket.off('question_update', this.updateQuestions);
    },
    loadNewQuestions(data) {
      const question = data.question;
      this.$store.commit('questions/addQuestion', question);
    },
    updateQuestions(data) {
      const question = data.question;
      const index = this.questions.findIndex(item => item.id === question.id);
      if (index !== -1) {
        if (this.userId && this.userId !== question.userId && question.status === 'rejected') {
          this.$store.commit('questions/deleteQuestion', index);
        } else {
          this.$store.commit('questions/updateQuestion', { question, index });
        }
      }
    },
    showReplyToQuestionModal(data) {
      this.$vfm.show('reply-to-question-modal', data);
    },
    search(filterText) {
      this.filterText = filterText;
    },
    filter(filter) {
      this.filterValue = filter.value;
      this.typeValue = filter.type;
    },
    sorting(sort) {
      this.sortingValue = sort.value;
    },
    speackerFilter(filterSpeacker) {
      this.filterSpeacker = filterSpeacker;
    },
    toRooms() {
      this.$router.push('/');
    },
    logout() {
      this.$store.dispatch('user/logout');
    },

  },
  computed: {
    userId() {
      return this.$store.getters['user/userId']
    },
    chatRoom() {
      return this.$store.getters['chatrooms/getChatroomById'](this.$route.params.roomId)
    },
    speechName() {
      return this.chatRoom?.speech?.title ?? ""
    },
    streamName() {
      return this.chatRoom?.stream?.name ?? ""
    },
    sortingQuestions() {
      const sorting = this.sortingValue

      if (!sorting) {
        return this.speackerFilterQuestions
      }
      
      if (sorting === 'asc') {
        return [...this.speackerFilterQuestions].sort((questionA,questionB) => {
          return questionA.likes - questionB.likes
        })
      }

      if (sorting === 'desc') {
        return [...this.speackerFilterQuestions].sort((questionA,questionB) => {
          return questionB.likes - questionA.likes
        })
      }
      return this.speackerFilterQuestions
    },
    
    filteredQuestions() {
      const filter = this.filterValue
      const type = this.typeValue

      if (!filter) {
        return this.searchQuestions
      }

      return this.searchQuestions.filter((question) => {
        if (type === 'answer') {
          const questionFilter = filter === 'answered'
          return question?.is_answered === questionFilter
        } else {
          return filter === 'all' || question.status === filter
        }
      })
    },
    searchQuestions() {
      const search = this.filterText

      if (!search) {
        return this.questions
      }

      return searchInArrayFields(search, ['userName', 'text'], this.questions)
    },
    speackerFilterQuestions() {
      const filterSpeacker = this.filterSpeacker

      if (!filterSpeacker) {
        return this.filteredQuestions
      }
      return [...this.filteredQuestions].filter((question) => {
        return question.speaker?.id === filterSpeacker || filterSpeacker === 'all'
      })
    },
    questions() {
      const questions = this.$store.getters['questions/getQuestions']
      questions.forEach(question => {
        question.userName = question?.admin?.name ?? question?.user?.name ?? ""
        question.userId = question?.admin?.id ?? question?.user?.id ?? ""
        question.userEmail = question?.admin?.email ?? question?.user?.email ?? ""
        question.userBan = question?.user?.ban ?? false
        question.isAdmin = !!question?.admin?.id ?? false
        if (question.is_reply) {
          question.userReply = questions.find(item => question.reply_id === item.id).admin?.name ?? questions.find(item => question.reply_id === item.id).user?.name
        }
      })
      return questions
    },
    speakers() {
      const speakers = []
      speakers.push({
        label: this.$t(`chat.all_speakers`),
        value: 'all',
      })
      this.questions.forEach((item)=> {
        if(!speakers.some(speaker => speaker.value === item.speaker?.id) && item.speaker?.id) {
          speakers.push({
            label: item.speaker?.name,
            value: item.speaker?.id,
          })
        }
      })
      return speakers
    }
  }
}
</script>

<style scoped lang="scss">
.questions{
  display: flex;
  margin: 0 20px 20px 20px;
}
.main-content {
  width: 100%;
  height: 100%;
  padding-left: 347px;

  .ZeenTitle--title {
    margin-top: 80px;
    font-size: 24px;
    font-weight: 400;
  }
}
.header {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #E5E5E5;
}
.buttons {
  display: flex;
  justify-content: space-between;
  &__exit {
    .icon-upload {
      transform: rotate(90deg);
      margin-right: 10px;
    }
    .icon-arrow {
      margin-right: 10px;
    }
  }
}
</style>