import i18n from '../../locales'
import zeenApi from '../../api/api'

/*
en, :ru, :bg, :de, :it, :et, :pl, :ro, :cs
*/

export default {
  namespaced: true,
  state: {
    lang: null,
    playerLang: null,
    availableLangs: [],
  },
  mutations: {
    setUserLangHeader(state, locale) {
      if (locale) {
        zeenApi.setUpLang(locale)
      }
    },
    setLang(state, lang) {
      if (lang) {
        zeenApi.setUpLang(lang)
      }

      i18n.locale = lang
      state.lang = lang
    },
    setPlayerLang(state, lang) {
      state.playerLang = lang
    },
    setAvailableLangs(state, langs) {
      state.availableLangs = langs
    },
  },
  getters: {
    availableLangs(state) {
      return state.availableLangs
    },
    playerLang(state) {
      return state.playerLang
    },
    currentLangObj(state) {
      const langObj = state.availableLangs.find(
        (lang) => lang.key === state.lang,
      )
      return langObj ? langObj : null
    },
  },
}
