
export const DEFAULT_LANG = 'ru'

export const langs = [
  {
    key: 'en',
    label: 'en',
    title: 'English (Английский)',
  },
  {
    key: 'ru',
    label: 'ru',
    title: 'Русский (Russian)',
  },
]

export const langKeys = ['en', 'ru']
