<template>
  <div id="app">
    <router-view></router-view>
    <NotificationsComponent/>
  </div>
</template>

<script>
import NotificationsComponent from './components/notifications/NotificationsComponent'
import {langs, langKeys, DEFAULT_LANG} from './mock/langs'
import {useOnlyDefaultLang} from './api/config'

export default {
  name: 'App',
  components: {NotificationsComponent},
  mounted() {
    this.$store.commit('lang/setAvailableLangs', langs)
    this.setLang()
  },
  methods: {
    setLang() {
      if (useOnlyDefaultLang) {
        this.setDefaultLang()
      } else {
        this.findLangToSet()
      }
    },
    setDefaultLang() {
      if (!this.lang || this.lang !== DEFAULT_LANG) {
        this.$store.commit('lang/setLang', DEFAULT_LANG)
      }
    },
    findLangToSet() {
      if (!this.lang) {
        this.setLangDependsOnBrowserLang()
      }
    },
    setLangDependsOnBrowserLang() {
      let lang = window.navigator.languages
        ? window.navigator.languages[0]
        : null

      lang =
        lang ||
        window.navigator.language ||
        window.navigator.browserLanguage ||
        window.navigator.userLanguage ||
        DEFAULT_LANG

      let shortLang = lang

      if (shortLang.indexOf('-') !== -1) shortLang = shortLang.split('-')[0]

      if (shortLang.indexOf('_') !== -1) shortLang = shortLang.split('_')[0]

      if (shortLang) {
        const shortLangFormatted = shortLang.toLowerCase()
        this.setBrowserLang(shortLangFormatted)
      } else {
        this.$store.commit('lang/setLang', DEFAULT_LANG)
      }
    },
    setBrowserLang(browserLang) {
      const isLangInList = langKeys.includes(browserLang)

      if (isLangInList) {
        this.$store.commit('lang/setLang', browserLang)
      } else {
        this.$store.commit('lang/setLang', DEFAULT_LANG)
      }
    }
  }
}
</script>

<style>
#app {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  font-family: sans-serif;
}
</style>
