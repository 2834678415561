<template lang="pug">
  .chat
    SidePanelChat(
      @search='search'
      @filter='filter'
      :speechName='speechName'
      :streamName='streamName'
    )
    .main-content
      .header
        .buttons
          ZeenButton(
            theme="string"
            @click.prevent="toRooms").buttons__exit
            .icon-arrow
            p {{$t(`chat.back_btn`)}}
        .buttons
          ZeenButton(
            theme="string"
            @click.prevent="logout").buttons__exit
            .icon-upload
            p {{$t(`chat.logout_btn`)}}
      Messages(
        :messages='filteredMessages'
        :speechName='speechName'
        :streamName='streamName'
        @show-reply-to-message-modal="showReplyToMessageModal")
      ZeenButton.main-content__write-message(@click.prevent="showWriteMessageModal")
        span {{$t(`chat.write_message_btn`)}}
    ReplyToMessageModal
    WriteMessageModal
    
</template>

<script>
import SidePanelChat from '../components/layout/SidePanelChat';
import Messages from '../components/chat/Messages';
import ReplyToMessageModal from '../components/modals/ReplyToMessageModal';
import WriteMessageModal from '../components/modals/WriteMessageModal';
import {searchInArrayFields} from '../helpers/searchable'
import CentrifugeSocket from '../helpers/CentrifugeSocket'


export default {
  name: "Chat",
  components: {WriteMessageModal, ReplyToMessageModal, Messages, SidePanelChat},
  data() {
    return {
      filterText: '',
      filterValue: null,
      roomId: null,
    }
  },
  async mounted() {
    await this.$store.dispatch('chats/loadChats', this.$route.params.roomId)
    await this.initCentrifuge();
    this.centrifugeChannelSubscribe();
    this.roomId = this.$route.params.roomId;
  },
  destroyed() {
    this.offCentrifuge();
  },
  methods: {
    initCentrifuge() {
      this.$api.project['admin.authorizations']()
        .then((data) => {
          CentrifugeSocket.connect({
            centrifugeToken: data.data.token,
          });
        })
        .catch((data) => {
          CentrifugeSocket.connect({
            centrifugeToken: data.data.token,
          });
        }); 
    },
    centrifugeChannelSubscribe() {
      CentrifugeSocket.subscribe(`admin-comments-chat-room-${this.$route.params.roomId}`)
      CentrifugeSocket.on('chat_item_create', this.loadNewChatItem)
      CentrifugeSocket.on('chat_item_update', this.updateChatItem)      
    },
    offCentrifuge() {
      CentrifugeSocket.unsubscribe(`admin-comments-chat-room-${this.roomId}`)
      CentrifugeSocket.off('chat_item_create', this.loadNewChatItem)
      CentrifugeSocket.off('chat_item_update', this.updateChatItem)      
    },
    loadNewChatItem(data){
      const chat_item = data.chat_item
      this.$store.commit('chats/addChatItem', chat_item)
    },
    updateChatItem(data){
      const chat_item = data.chat_item
      const index = this.chats.findIndex(item => item.id === chat_item.id)
      if (index === -1) {
        return
      }
      this.$store.commit('chats/updateChatItem', {chat_item, index})
    },

    
    showReplyToMessageModal(data) {
      this.$vfm.show('reply-to-message-modal', data)
    },
    showWriteMessageModal() {
      this.$vfm.show('write-message-modal')
    },
    search(filterText) {
      this.filterText = filterText
    },
    filter(filter) {
      this.filterValue = filter.value
    },
    toRooms() {
      this.$router.push('/')
    },
    logout() {
      this.$store.dispatch('user/logout')
    }
  },
  computed: {
    chatRoom() {
      return this.$store.getters['chatrooms/getChatroomById'](this.$route.params.roomId)
    },
    speechName() {
      return this.chatRoom?.speech?.title ?? ""
    },
    streamName() {
      return this.chatRoom?.stream?.name ?? ""
    },
    chats() {
      const chats = this.$store.getters['chats/getChats']
      if (!Array.isArray(chats)) {
        return [];
      }
      chats.forEach(chat => {
        chat.userName = chat?.admin?.name ?? chat?.user?.name ?? ""
        chat.userId = chat?.admin?.id ?? chat?.user?.id ?? ""
        chat.userEmail = chat?.admin?.email ?? chat?.user?.email ?? ""
        chat.userBan = chat?.user?.ban ?? false
        chat.isAdmin = !!chat?.admin?.id ?? false
        if (chat.is_reply) {
          chat.userReply = chats.find(item => chat.reply_id === item.id).admin?.name ?? chats.find(item => chat.reply_id === item.id).user?.name
        }
      });
      return chats
    },
    filteredMessages() {
      const filter = this.filterValue

      if (!filter) {
        return this.searchMessages
      }
      return [...this.searchMessages].filter((message) => {
        return message.status === filter || filter === 'all'
      })
    },
    searchMessages() {
      const search = this.filterText

      if (!search) {
        return this.chats
      }

      return searchInArrayFields(search, ['userName', 'text'], this.chats)
    },
  } 
}
</script>

<style scoped lang="scss">
.header {
  display: flex;
}
.chat{
  display: flex;
  margin: 0 20px 20px 20px;
}
.main-content {
  width: 100%;
  height: 100%;
  padding-left: 347px;
  padding-bottom: 100px;

  .ZeenTitle--title {
    margin-top: 80px;
    font-size: 24px;
    font-weight: 400;
  }

  &__write-message.zeen-button_filled {
    position: fixed;
    bottom: 10px;
    left: 377px;
    width: calc(100% - 402px);
  }
}
.header {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #E5E5E5;
}
.buttons {
  display: flex;
  justify-content: space-between;
  &__exit {
    .icon-upload {
      transform: rotate(90deg);
      margin-right: 10px;
    }
    .icon-arrow {
      margin-right: 10px;
    }
  }
}
</style>