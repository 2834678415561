export default {
  data() {
    return {
      // first value is empty for 'acceptedPictureFiles.join(", .")'
      // Сейчас принимается только png
      // acceptedPictureFiles: ['', 'png', 'jpeg', 'jpg'],
      acceptedPictureFiles: ['', 'png', 'jpeg', 'jpg'],
    }
  },
  methods: {
    // you need to wrap the method in async, and the call validatePicture(file) in await
    validatePicture(file) {
      // validate for file extension
      const fileNameParts = file.name.split('.')
      const fileExtension = fileNameParts.pop().toLowerCase()
      if (!this.acceptedPictureFiles.includes(fileExtension)) {
        this.$notify({
          type: 'error',
          text: this.$t('modals.write_message.error_extension'),
        })
        return false
      }

      // validate for file size
      const size = 1024 * 1024 * 10
      if (file.size > size) {
        this.$notify({
          type: 'error',
          text: this.$t('modals.write_message.error_limit'),
        })
        return false
      }

      return true

      // validate for image size
      // return new Promise((resolve) => {
      //   let reader = new FileReader()
      //   reader.readAsDataURL(file)
      //   const that = this
      //   reader.onload = function (e) {
      //     let image = new Image()
      //     image.src = e.target.result
      //     image.onload = function () {
      //       let height = this.height
      //       let width = this.width
      //       if (width > 1000 || height > 1000) {
      //         that.$notify({
      //           type: 'error',
      //           text: 'Масштаб изображения не более 1000x1000 пикселей!',
      //         })
      //         resolve(false)
      //       } else {
      //         resolve(true)
      //       }
      //     }
      //   }
      // })
    },
  },
}
