export default {
  "auth": {
    "title": "Авторизация",
    "login": "Email",
    "password": "Пароль",
    "password_forgot": "Забыли пароль?",
    "login_btn": "Войти",
  },

  "room_select": {
    "logout_btn": "Выйти",
    "tabs": {
      "chat": "Чаты",
      "questions": "Вопросы спикеру",
      "all_rooms": "Все комнаты"
    },
  },

  "chat": {
    "back_btn": "Назад",
    "logout_btn": "Выйти",
    "write_message_btn": "Написать сообщение",
    "all_speakers": "Все спикеры",
  },

  "questions": {
    "back_btn": "Назад",
    "logout_btn": "Выйти",
    "": "",
  },

  "side_panel_rooms": {
    "day_label": "День мероприятия",
    "day_select": "Выберите день...",
    "stream_label": "Зал",
    "stream_select": "Не выбрано...",
    "speach_label": "Выступление",
    "speach_select": "Не выбрано...",
    "reset": "Сбросить"
  },

  "side_panel_questions": {
    "search_title": "Поиск по текстам и авторам",
    "search_placeholder": "Введите текст...",
    "speaker_label": "Выберите спикера",
    "speaker_placeholder": "Спикер...",
    "filter_list": {
      "title": "Статус",
      "all": "все вопросы",
      "approved": "опубликованные",
      "rejected": "отклоненные",
      "pending": "на модерации",
      "answered": "отвечено",
      "not_answered": "не отвечено",
    },
    "sorting_list": {
      "title": "По лайкам",
      "all": "без сортироки",
      "asc": "по возрастанию",
      "desc": "по убыванию",
    },
    "buttons": {
      "export_excel": "Выгрузить в Excel",
    },
  },

  "side_panel_chat": {
    "search_label": "Поиск по текстам и авторам",
    "search_placeholder": "Введите текст...",
    "filter_list": {
      "title": "Статус",
      "all": "все сообщения",
      "approved": "опубликованные сообщения",
      "rejected": "заблокированные сообщения",
    },
    "buttons": {
      "export_excel": "Выгрузить в Excel",
    },
  },

  "selection_card": {
    "router_btn": "Перейти",
    "types": {
      "chat": "Чат",
      "questions": "Вопрос спикеру",
    },
  },

  "question": {
    "buttons": {
      "answer": "Ответить",
      "reject": "Отклонить",
      "post": "Опубликовать",
      "ban": "Заблокировать автора",
      "unban": "Разблокировать автора",
      "delete": "Удалить",
      "mark": {
        "true": "Снять отметку",
        "false": "Отметка об ответе",
      }
    },
    "answer": {
      "true": "Отвечено",
      "false": "Не отвечено",
    }
  },

  "message": {
    "buttons": {
       "answer": "Ответить",
       "reject": "Отклонить сообщение",
       "post": "Опубликовать сообщение",
       "ban": "Заблокировать автора",
       "unban": "Разблокировать автора",
       "delete": "Удалить",
    },
  },

  "ui": {
    "input_file": {
      "text": "Прикрепить изображение",
      "label": "Прикрепляемое изображение",
      "text_for_btn": "Заменить изображение",
      "requirements": "JPEG или PNG, не больше 1 Мбайт",
      "img_alt": "Изображение",
    },
    "tooltips": {
      "unban_autor": "Сначала необходимо разблокировать автора",
    },
    "select_lang": {
      "label": "Язык"
    }
  },

  "modals": {
    "write_message": {
      "title": "Написать сообщение",
      "description": "Текст сообщения",
      "placeholder": "Введите текст сообщения...",
      "input_file_text": "Прикрепить изображение",
      "input_file_label": "Прикрепляемое изображение",
      "input_file_btn": "Заменить изображение",
      "send_btn": "Отправить",
      "cancel_btn": "Отмена",
      "error_extension": "Файл не соответсвует формату (jpeg, jpg, png)",
      "error_limit": "Размер файла не может превышать 10мб!",
    },
    "reply_to_message": {
      "title": "Ответить на сообщение",
      "description": "Текст сообщения",
      "answer_description": "Текст ответа",
      "placeholder": "Введите текст сообщения...",
      "input_file_text": "Прикрепить изображение",
      "input_file_label": "Прикрепляемое изображение",
      "input_file_btn": "Заменить изображение",
      "send_btn": "Отправить",
      "cancel_btn": "Отмена",
    },
    "reply_to_question": {
      "title": "Ответить на сообщение",
      "description": "Текст вопроса",
      "answer_description": "Текст ответа",
      "placeholder": "Введите текст сообщения...",
      "is_ptivate_title": "Приватно",
      "send_btn": "Отправить",
      "cancel_btn": "Отмена",
    },
  },

  "notifications": {
    "all_answers_reviewed": "Вы посмотрели все ответы",
    "all_messages_reviewed": "Вы посмотрели все сообщения",
    "question_rejected": "Вопрос заблокирован",
    "question_approved": "Вопрос опубликован",
    "message_rejected": "Сообщение заблокированно",
    "message_approved": "Сообщение опубликованно",
    "error": "Ошибка",
    "user_was_banned": "Пользователь заблокирован",
    "user_was_unbanned": "Пользователь разблокирован",
    "successfully_sent": "Успешно отправленно",
    "answered": "Успешно изменена отметка об ответе",
  }
}