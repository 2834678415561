<template lang="pug">
  .input-file__wrapper
    .input-file(v-if='!image')
      label
        input.input-file__input(
          type="file"
          :accept="acceptedPictureFilesStr"
          @change="uploadPicture($event.target.files[0])"
        )
        .input-file__label(for="file")
          .icon-clip
          span {{textReal}}

    .attached-image(v-else)
      span {{labelReal}}
      .content-wrapper
        .content-wrapper__img
          img(:src="picture.url || picture" :alt="$t(`ui.input_file.img_alt`)")
        .content-wrapper__btn(@click.prevent='removePicture')
          .icon-close
        .replace-photo
          input.replace-photo__input(
            type="file"
            :accept="acceptedPictureFilesStr"
            id="replace-file"
            @change="uploadPicture($event.target.files[0])"
          )
          label.replace-photo__label(for="replace-file") {{textForBtnReal}}
            span {{$t(`ui.input_file.requirements`)}}

    .error(v-if="error")
      span {{error}}
</template>

<script>
import validateImage from '../../mixins/validateImage';

export default {
  name: 'InputFile',
  mixins: [validateImage],
  props: {
    text: String,
    image: [File, String, Object],
    label: String,
    textForBtn: String,
    error: String,
  },
  methods: {
    async uploadPicture(file) {
      // const isValidationPassed = await this.validatePicture(file)

      if (this.validatePicture(file)) {
        this.$emit('change-input', file);
      } else {
        console.log(this.validatePicture(file));
      }
    },
    removePicture() {
      this.$emit('remove');
    },
  },
  computed: {
    picture() {
      try {
        return URL.createObjectURL(this.image);
      } catch (err) {
        return this.image;
      }
    },
    textReal() {
      return this.text ?? this.$t(`ui.input_file.text`);
    },
    labelReal() {
      return this.label ?? this.$t(`ui.input_file.label`);
    },
    textForBtnReal() {
      return this.textForBtn ?? this.$t(`ui.input_file.text_for_btn`);
    },
    acceptedPictureFilesStr() {
      return this.acceptedPictureFiles.map(i => `.${i}`).splice(1).join(',')
    },
  },
};
</script>

<style lang="scss" scoped>
.input-file {
  margin-top: 10px;

  &__input {
    visibility: hidden;
    position: absolute;
  }

  &__label {
    width: max-content;
    display: flex;
    align-items: center;
    font-size: 12px;
    font-weight: 600;
    color: #a672ff;
    cursor: pointer;

    &:hover {
      color: #7b35f1;
    }

    .icon-clip {
      margin-right: 10px;
      font-size: 26px;
    }
  }
}

.attached-image {
  margin-top: 10px;
  font-size: 12px;
  color: #999;
}

.content-wrapper {
  display: flex;
  align-items: center;
  position: relative;

  &__img {
    position: relative;
    margin: 5px 24px 0 0;

    img {
      position: relative;
      width: 180px;
      height: 130px;
      object-fit: cover;
      border-radius: 10px;
    }
  }

  &__btn {
    cursor: pointer;
    position: absolute;
    top: 12px;
    left: 145px;
    background-color: #fff;
    font-size: 8px;
    color: #999;
    padding: 6px;
    border-radius: 50%;
  }
}

.replace-photo {
  display: flex;
  justify-content: center;
  align-items: center;

  &__input {
    position: absolute;
    visibility: hidden;
  }

  &__label {
    display: flex;
    flex-direction: column;
    color: #a672ff;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;

    span {
      max-width: 220px;
      font-size: 12px;
      font-weight: 400;
      color: #999;
      padding-top: 8px;
    }
  }
}

.error {
  span {
    margin-top: 0.5rem;
    font-size: 0.7rem;
    color: #ff4d4d;
  }
}
</style>
