<template lang="pug">
  .form
    ZeenTitle.title(
      size="1"
      uiStyle="")
      span {{$t(`modals.reply_to_question.title`)}}
    .author
      .author__avatar
        .icon-profile
      span {{userName}}
      .icon-direction
      .recipient
        .recipient__avatar
          .icon-profile
      span {{userReplyName}}
    .message
      .message__description {{$t(`modals.reply_to_question.description`)}}
      span {{text}}
    .answer
      .answer__description {{$t(`modals.reply_to_question.answer_description`)}}
      ZeenTextInput(
        type="textarea"
        v-model='question'
        :placeholder="$t(`modals.reply_to_question.placeholder`)")
      ZeenCheckbox(
        class="answer__checkbox"
        :label="$t(`modals.reply_to_question.is_ptivate_title`)"
        v-model='isPrivate'
      )
    .buttons
      ZeenButton.buttons__button.buttons__button-outline(
        theme="outline"
        @click.prevent="closeModal") {{$t(`modals.reply_to_question.cancel_btn`)}}
      ZeenButton.buttons__button(@click.prevent='sendMessage') {{$t(`modals.reply_to_question.send_btn`)}}
</template>

<script>
export default {
  name: 'ReplyToQuestionForm',
  data() {
    return {
      file: '',
      question: '',
      isPrivate: false
    }
  },
  props: {
    isOpen: Boolean,
    data: Object
  },
  methods: {
    fileExists() {
      this.file = this.$refs.file.files[0]
    },
    closeModal() {
      this.$vfm.hide('reply-to-question-modal')
    },    
    sendMessage() {
      this.$store.dispatch('questions/replyQuestion', {
        question_item: {
          reply: this.question,
          is_private: this.isPrivate
        },
        id: this.data.id
      })
      .then(()=>{
        this.$store.dispatch('questions/loadQuestions', this.$route.params.roomId)
        this.$notify({
          type: 'success',
          text: this.$t(`notifications.successfully_sent`)
        })
        this.closeModal()
      })
      .catch((data)=>{
        this.$notify({
          type: 'error',
          text: this.$t(`notifications.error`) + data
        })
      })
      this.question = ""
    }
  },
  computed: {
    userName() {
      return this.data?.userName ?? ""
    },
    userReplyName() {
      return this.data?.speaker?.name ?? ""
    },
    text() {
      return this.data?.text ?? ""
    },
    
  },
  watch: {
    isOpen() {
      this.file = ''
      this.question = ''
      this.isPrivate = false
    }
  }
}
</script>

<style lang="scss" scoped>
  .title {
    font-size: 24px;
    font-weight: 600;
  }

  .author {
    padding-top: 30px;
    display: flex;
    align-items: center;
    font-size: 12px;
    font-weight: 600;

    &__avatar {
      width: 20px;
      height: 20px;
      border-radius: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #fff;
      font-size: 13px;
      background-color: #999;
      margin-right: 10px;
    }

    .icon-direction {
      color: #a672ff;
      font-size: 5px;
      padding: 0 10px;
    }

    .recipient {
      color: #a672ff;
      font-size: 12px;
      font-weight: 600;

      &__avatar {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #a672ff;
        width: 20px;
        height: 20px;
        border-radius: 20px;
        font-size: 13px;
        color: #fff;
        margin-right: 10px;
      }
    }
  }

  .message {
    padding-top: 27px;
    font-size: 18px;

    &__description {
      font-size: 12px;
      color: #999;
      padding-bottom: 6px;
    }
  }

  .answer {
    padding-top: 24px;

    &__description {
      font-size: 12px;
      color: #999;
      padding-bottom: 6px;
    }

    &__checkbox {
      margin-top: 15px;
    }
  }

  .buttons {
    display: flex;
    padding-top: 30px;

    &__button {
      flex-grow: 1;
    }

    &__button-outline {
      margin-right: 15px;
    }
  }
</style>