<template lang="pug">
  vue-final-modal(
    v-bind='$attrs',
    classes='modal-container',
    :content-class='["", contentClass, theme]',
    v-on='$listeners'
  )
    template(v-slot='{params}')
      .content-wrapper
        slot(v-bind:params='params')

        ZeenButton.close-button(
          theme='string',
          @click='$emit("input", false)'
        )
          .icon-close
</template>

<script>
export default {
  name: 'ZeenModal',
  inheritAttrs: false,
  props: {
    contentClass: String,
    theme: {
      type: String,
      default: 'light',
      validator: (theme) => ['dark', 'light'].includes(theme),
    }
  },
}
</script>

<style lang="scss" scoped>
.modal-visible {
  .content-wrapper {
    overflow: visible;
  }
}
::v-deep .vfm__content {
  margin: auto;
}
::v-deep .modal-container {
  z-index: 1000;
  display: flex;
  overflow: auto;
  padding: 50px 0;
}
::v-deep .modal-user {
  position: relative;
  display: flex;
  flex-direction: column;
  max-width: 754px;
  width: 100%;
  max-height: 605px;
  height: 100%;
  margin: auto;

  background: #181818;
  border-radius: 15px;
  &.light {
    background: #fff;
    color: #222222;
  }
}
::v-deep .modal-tickets {
  position: relative;
  display: flex;
  flex-direction: column;
  max-width: 390px;
  width: 100%;
  max-height: 555px;
  margin: auto;

  background: #181818;
  border-radius: 15px;
  &.light {
    background: #fff;
    color: #222222;
  }
}
::v-deep .modal-user {
  .content-wrapper {
    width: 100%;
  }
}
::v-deep .modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 500px;
  margin: auto;
  border-radius: 15px;
  background: transparent;
  border: 0;

  &.no-padding {
    padding: 0;
  }

  &.semi-small {
    max-width: 450px;
  }

  &.small {
    max-width: 450px;
    background: #272727;
  }

  &.pre-small {
    max-width: 500px;
  }

  &.middle {
    max-width: 600px;
  }

  &.pre-middle {
    max-width: 700px;
  }

  &.wide {
    max-width: 860px;

    .close-button {
      width: 50px;
      height: 50px;

      svg {
        width: 12px;
      }
    }
  }

  &.semi-wide {
    max-width: 953px;
  }

  &.badge {
    max-width: 380px;
    background: transparent;

    .close-button {
      top: 10px;
      right: 10px;
    }
  }
}

.content-wrapper {
  position: relative;
  padding: 35px 35px 50px;
  background: #fff;
  width: 474px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow-y: auto;
  scrollbar-width: none;
  border-radius: 10px;
}

.close-button {
  position: absolute !important;
  top: 0;
  right: 20px;
}
</style>