<template lang="pug">
  .questions-list
    Question(
      v-for="(question, index) of questions"
      :key='index'
      :question="question"
      :questions="questions"
      @show-replay-to-question-modal="showReplyToQuestionModal")
</template>

<script>
import Question from './Question';
export default {
  name: "QuestionsList",
  components: {Question},
  props: {
    questions: Array
  },
  mounted() {
    this.showMessageScrollBottom()
  },
  destroyed() {
    this.hiddenMessageScrollBottom()
  },
  methods: {
    showReplyToQuestionModal(data) {
      this.$emit('show-replay-to-question-modal', data)
    },
    showNotify() {
      let scrollTop = document.documentElement.scrollTop
      let innerHeight = window.innerHeight
      let offsetHeight = document.documentElement.offsetHeight
      let bottomOfWindow = scrollTop + innerHeight === offsetHeight

      if (bottomOfWindow) {
        this.$notify({
          type: 'success',
          text: this.$t(`notifications.all_answers_reviewed`),
          max: 1,
          ignoreDuplicates: true
        })
      }
    },
    showMessageScrollBottom() {
      window.addEventListener('scroll', this.showNotify, {
        capture: false,
        once: false
      })
    },
    hiddenMessageScrollBottom() {
      window.removeEventListener('scroll', this.showNotify)
    }
  }
}
</script>

<style lang="scss" scoped>
  .questions-list {
    position: relative;
    flex-direction: column;
    padding-bottom: 144px;
  }

  ::v-deep .vue-notification-group {
    position: absolute;
    bottom: 0 !important;
  }
</style>