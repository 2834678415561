<template lang="pug">
  .side-panel
    .side-panel__content
      ZeenLogo(
        color='#A672FF'
        :width='116'
        :height='57'
      )
      ZeenTitle.side-panel__title(
        size="1"
        uiStyle="title")
        span(v-if="speechName") {{speechName}}  /
        span  {{streamName}}
      .input-search
        .input-search__label {{$t(`side_panel_questions.search_title`)}}
        input.input-search__input(v-model="filterText" :placeholder='$t(`side_panel_questions.search_placeholder`)')
        .icon-magnifier.input-search__icon
      ZeenSelect.side-panel__select(
        :label="$t(`side_panel_questions.speaker_label`)"
        @input="changeSpeakers"
        :placeholder="$t(`side_panel_questions.speaker_placeholder`)"
        :value='speaker'
        :list='speakers'  
      )
      .select-wrapper__wrap 
        SelectFilter(
          class="select-wrapper"
          :active='activeFilter',
          :list='listFilter',
          :nameFilter='nameFilter',
          @input='changeFilter'
        )
        SelectFilter(
          class="select-wrapper"
          :active='activeSorting',
          :list='listSorting',
          :nameFilter='nameSort',
          @input='changeSort'
        )

      ZeenButton.side-panel__btn-file(@click.prevent='exportQuestions')
        .icon-file
        span {{$t(`side_panel_questions.buttons.export_excel`)}}
    .side-panel__lang
      SelectLang
</template>

<script>
import ZeenLogo from '../ui/ZeenLogo'
import SelectFilter from '../ui/SelectFilter'
import SelectLang from '../ui/SelectLang'

export default {
  name: "SidePanelRoomsQuestions",
  components: {
    ZeenLogo, 
    SelectFilter,
    SelectLang
  },
  props: {
    streamName: String,
    speechName: String,
    speakers: Array,
  },
  data() {
    return {
      speaker: null,
      filterText: "",
      activeFilter: {value: 'all', label: this.$t(`side_panel_questions.filter_list.all`)},
      listFilter: [
        {value: 'all', label: this.$t(`side_panel_questions.filter_list.all`)},
        {value: 'approved', label: this.$t(`side_panel_questions.filter_list.approved`)},
        {value: 'rejected', label: this.$t(`side_panel_questions.filter_list.rejected`)},
        {value: 'pending', label: this.$t(`side_panel_questions.filter_list.pending`)},
        {value: 'answered', label: this.$t(`side_panel_questions.filter_list.answered`), type: 'answer'},
        {value: 'not_answered', label: this.$t(`side_panel_questions.filter_list.not_answered`), type: 'answer'},
      ],
      nameFilter: this.$t(`side_panel_questions.filter_list.title`),
      activeSorting: {value: 'all', label: this.$t(`side_panel_questions.sorting_list.all`)},
      listSorting: [
        {value: 'all', label: this.$t(`side_panel_questions.sorting_list.all`)},
        {value: 'asc', label: this.$t(`side_panel_questions.sorting_list.asc`)},
        {value: 'desc', label: this.$t(`side_panel_questions.sorting_list.desc`)},
      ],
      nameSort: this.$t(`side_panel_questions.sorting_list.title`),
      activeType: null,
    }
  },
  methods: {
    changeFilter(filter) {
      this.activeFilter = filter
      this.$emit('filter', this.activeFilter)
      this.$emit('type', this.activeType)
    },
    changeSort(sortValue) {
      this.activeSorting = sortValue
      this.$emit('sorting', this.activeSorting)
    },
    changeSpeakers(value) {
      this.speaker = value
      this.$emit('speackerFilter', value)
    },
    exportQuestions() {
      this.$api.project['admin.export'](this.$route.params.roomId).catch(
        (response) => {
          const blobFile = new Blob([response.data])
          const url = window.URL.createObjectURL(blobFile)
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', this.$route.params.roomId + '-questions.xls')
          document.body.appendChild(link)
          link.click()
          document.body.removeChild(link)
        },
      )
    },
  },
  watch: {
    filterText() {
      this.$emit('search', this.filterText)
    }
  } 
}
</script>

<style scoped lang="scss">
  .side-panel {
    position: fixed;
    left: 0;
    top: 0;
    max-width: 347px;
    width: 100%;
    height: 100%;
    background-color: #222;
    padding: 20px 30px 0 40px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &__title.title {
      font-weight: 600;
      font-size: 18px;
      line-height: 22px;
      color: #FFFFFF;
      margin-top: 60px;
    }
    &__btn-file .icon-file{
      margin-right: 5px;
    }
    .side-panel__select {
      padding-top: 12px;
      margin-top: 20px;

      ::v-deep .z-input__wrapper {
        background-color: #343434;
      }
      ::v-deep .z-input__select {
        color: #FFFFFF;
      }

      ::v-deep .z-input__dropdown-item {
        color: #fff;

        &:hover {
          color: #222;
        }

        &_selected {
          color: #222;
        }
      }

    }

    &__lang {
      padding: 50px 0;
    }
  }

  .buttons {
    display: flex;
    justify-content: space-between;
    &__exit {
      .icon-upload {
        transform: rotate(90deg);
        margin-right: 10px;
      }
    }

    .icon-chevron-down {
      transform: rotate(90deg);
    }
  }
  .input-search {
    display: flex;
    flex-direction: column;
    margin-top: 40px;
    position: relative;

    &__label {
      color: #999;
      font-size: 12px;
      margin-bottom: 6px;
      bottom: 110px;
    }

    &__input {
      padding: 20px 20px 20px 46px;
      background-color: #343434;
      border-radius: 10px;
      border: none;
      outline: none;
      color: #fff;
      font-size: 16px;
    }

    &__icon {
      position: absolute;
      top: 40px;
      left: 20px;
      width: 20px;
      color: #999;
      cursor: pointer;
    }
  }
  .select-wrapper {
    display: flex;
    align-items: center;
    font-size: 16px;
    margin-top: 0px;

    &__description {
      color: #999;
      padding-right: 10px;
      margin-bottom: 10px;
      margin-top: 10px;
    }
    &__value {
      font-weight: 600;
      color: #a672ff;
      margin: 0;
    }
    .icon-chevron-down {
      font-size: 8px;
      color: #a672ff;
      padding: 2px 0 0 3px;
    }
    &__wrap {
      margin: 20px 0;
    }
  }
</style>